.footer {
  background-color: #11062B;
  color: rgba($color: #ffffff, $alpha: 0.9);
  font-family: $poppins;
  font-weight: 300;
  padding-top: 2rem;

  @media (max-width: 767px) {
    text-align: center;
  }

  hr {
    border: 1px solid rgba($color: #ffffff, $alpha: 0.3);
    // width: 89%;
  }

  .blog-img {
    width: 57%;
  }

  p {
    font-family: $poppins;
    font-weight: 400;
    font-size: 15px;
    // @media (max-width: 1400px) {
    //     font-size: 0.7rem;
    // }
  }

  .zip-p {
    font-family: $poppins;
    font-weight: 300;
    font-size: 13px;
    margin-top: 1rem;
    margin-bottom: 0;
  }

  .zzp {
    margin-top: 0rem;
  }

  h5, .connect {
    font-family: $poppins;
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;

    span {
      color: #5FF7FF;
    }
  }

  .heading {

    @media (max-width: 1600px) {
      font-size: 1rem;
    }
  }

  li {
    margin-top: 1rem;
  }

  a {
    color: rgba($color: #ffffff, $alpha: 0.9);
    font-size: 15px;
    font-family: $poppins;
    font-weight: 400;
    // @media (max-width: 1400px) {
    //     font-size: rem;
    // }

  }

  .change {
    font-size: 12px;
    font-family: $poppins;
    font-weight: 400;
  }
  .partners-div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
   
      @media (max-width: 1650px) {
       img {
        width: 40%;
       }
    }
    .hide {
      visibility: hidden;
    }
  }
  .social {
    img {
      margin-right: 0.5rem;
      margin-bottom: 10px;
      width: 30px;
    }
  }
}