.nft {
    background-color: #000000;
    background: url('../../images/privacy/background.png');
    border-bottom: 1px solid #11062B;
  
    .heading {
      font-family: $poppins;
      font-weight: 600;
      color: #FFFFFF;
      margin-top: 5rem;
      @media (max-width: 767px) {
          display: none;
      }
    }

    h5 {
      font-family: $poppins;
      font-weight: 500;
      color: #FFFFFF;
    }
    .mobile {
        display: none;
        @media (max-width: 767px) {
            display: initial;
           }
           h5 {
            margin-top: 3rem;
            margin-bottom: 2rem;
           }
    }
    .web {
        @media (max-width: 767px) {
            display: none;
           }
    }
  .left {
      text-align: center;
      h3 {
        color: rgba(255,255, 255, 0.7);
        margin-bottom: 10rem;
        font-family: $poppins;
        font-weight: 600;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .m-head {
        display: none;
        margin-bottom: 0rem;
        @media (max-width: 767px) {
            display: initial;
           }
    }
    .m-h {
        display: none;
        margin-bottom: 0rem;
        color: #5FF7FF;
        @media (max-width: 767px) {
            display: block;
           }
    }
      p {
        color: rgba(255,255, 255, 0.7);
        width: 70%;
        margin: 0 auto;
        margin-bottom: 5rem;
        @media (max-width: 767px) {
        width: 93%;
        margin-bottom: 2.8rem;
        }
      }

      .per {
          color: #5FF7FF;
          @media (max-width: 767px) {
          display: none;
          }
      }

      .mbile-nft {
        display: none;
        margin:  0 auto;
        margin-bottom: 2.5rem;
        @media (max-width: 767px) {
          display: block;
          }
      }
      .img-div {
        width: 85%;
        margin: 0 auto;
        margin-top: 5rem;
        @media (max-width: 767px) {
          display: none;
          }
      img {
          margin-left: 3rem;
        }
      }
      span {
          color: #5FF7FF;
      }
  }
    .right {
      text-align: center;
      margin-top: 14rem;
      @media (max-width: 767px) {
       margin-top: 2rem;
       margin-bottom: 3rem;
      }
.per {
  display: none;
  @media (max-width: 767px) {
    display: block;
    margin-top: 2.5rem;
   }
}
h6 {
  display: none;
  color: #FFFFFF;
  @media (max-width: 767px) {
    display: block;
    // margin-top: 4rem;
   }
}
      .count {
        span {
            color: #5FF7FF;
            font-size: 3rem;
            letter-spacing: 8px;
        }
    }
      h1 {
        color: #5FF7FF;
        margin-top: 1rem;
      }
  
      .time {
        display: flex;
        justify-content: space-around;
        width: 44%;
        margin: 0 auto;
        @media (max-width: 1500px) {
            width:  64%;
        }
        @media (max-width: 767px) {
        width: 79%;
        justify-content: space-between;
        }
        span {
          color: rgba(255, 255, 255, 0.5);
          font-size: 12px;
          text-align: center;
          display: block;
          font-family: $poppins;
            font-weight: 300;
        }
  
        .line {
          display: block;
          width: 2px;
          height: 1.5rem;
          background-color: #FFFFFF;
          margin-top: 2rem;
        }
      }
  
    }
  }