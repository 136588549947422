.pasword {
  margin: 0 auto;
  margin-top: 5rem;

  input {
    width: 50%;
  }

  .btn {
    margin-top: 1rem;
    width: 50%;
  }
}

.privacy {
  background: #000000 0% 0% no-repeat padding-box;
  color: #ffffff;
  background: url('../images/privacy/background.png');

  .box {
    width: 70%;
    margin: 0 auto;
    text-align: center;

    ul {
      list-style: none;
    }

    h5 {
      margin-top: 2rem;
      font-size: 18px;
      font-family: $poppins;
      font-weight: 600;
    }

    p {
      font-family: $poppins;
      font-weight: 400;
      font-size: 15px;
    }
  }
}

.terms {
  background: url('../images/privacy/background.png');
  color: #ffffff;

  .box {
    width: 70%;
    margin: 0 auto;
    text-align: justify;

    h5 {
      margin-top: 2rem;
      font-size: 18px;
      font-family: $poppins;
      font-weight: 600;
    }

    .heading {
      color: #5FF7FF;
    }

    p {
      font-family: $poppins;
      font-weight: 400;
      font-size: 15px;
    }

    .colorful {
      background: linear-gradient(#4A289C, #00092B);
      padding: 0.5rem;
      border-radius: 10px;
    }
  }

}

.react-responsive-modal-modal {
  background-color: #0A1A1A;
  max-width: 665px;
  text-align: center;
  margin: 6.2rem;
  border-radius: 19px;
  font-family: $poppins;

  @media (max-width: 767px) {
    margin: 0rem;
    margin-top: 8rem;
    width: 361px;
  }

  .manual {
    display: block;
    margin: 0 auto;
  }

  h5 {
    color: #5FF7FF;
    margin-top: 1rem;
    font-weight: 600;
    font-size: 1rem;
  }

  h4 {
    color: #5FF7FF;
    margin-top: 1rem;
    font-weight: 600;
  }

  h6 {
    color: #ffffff;
    margin-top: 1rem;
    font-weight: 600;
  }

  p {
    color: #ffffff;
    font-weight: 400;
    font-size: 12px;
    width: 85%;
    margin: 0 auto;
    margin-bottom: 1rem;

    .cryp {
      color: #5FF7FF;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .copy {
    position: absolute;
    top: 51.8%;
    right: 24%;
    width: 2%;

    @media (max-width: 767px) {
      top: 65%;
      right: 8%;
      width: 4%
    }
  }

  .disc {
    text-align: left;
    width: 100%;
    margin-top: 1rem;
  }

  .cont {
    border: 1px solid #ffffff;
    border-radius: 30px;
    background-color: #0A1A1A;
    color: #5FF7FF;
    margin-bottom: 0.5rem;
    font-size: 14px;
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  }


  .dis {
    font-weight: 400;
    display: block;
    color: #5FF7FF;
    margin-bottom: 1rem;
    font-size: 14px;
    margin: 0 auto;
  }

  .address {
    width: 92%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 2rem;

    @media (max-width: 767px) {
      font-size: 11px;
    }
  }

  .cross {
    background: transparent;
    border: none;
    color: #5FF7FF;
    position: absolute;
    top: 3%;
    right: 2%;
    z-index: 1;
    cursor: pointer;
  }

  .react-responsive-modal-closeButton {
    display: none !important;
  }

}

.meta {
  h5 {
    font-size: 22px;
    margin-bottom: 1rem !important;
  }

  small {
    font-size: 15px;
    margin-bottom: 2rem;
    display: block;
  }

  .form-control {
    width: 60% !important;
    padding: 23px;
  }

  .btn {
    width: 24% !important;
    padding: 0.7rem;
    border: 1px solid #ffffff !important;
    border-radius: 24px !important;
  }

  h6 {
    font-weight: 500 !important;

    span {
      color: #5FF7FF;
      cursor: pointer;
    }
  }
}

.private {
  max-width: 665px !important;

  h5 {
    font-weight: 500 !important;
  }

  .first-p {
    font-size: 14px;
  }

  .sec-p {
    width: 73%;
  }

  span,
  a {
    color: #5FF7FF;
  }

  .eth-div {
    display: flex;
    width: 50%;
    margin: 0 auto;
    justify-content: space-between;
    margin-bottom: 2rem;

    p {
      width: 100%;
      margin-bottom: 0rem;
    }

    .line {
      width: 1px;
      height: 2.8rem;
      background-color: #5FF7FF;
    }
  }

  .address-div {
    width: 60%;
    margin: 0 auto;
    border: 1px solid rgba($color: #5FF7FF, $alpha: 0.5);
    border-radius: 17px;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  h6 {
    font-weight: 400 !important;
    font-size: 13px !important;
  }

  .hr-div {
    width: 50%;
    margin: 0 auto;
    display: flex;

    hr {
      border: 1px solid rgba($color: #ffffff, $alpha: 0.5);
      width: 44%;
    }

    p {
      margin-top: 9px;
      width: auto;
    }
  }

}

.sale-modal {
  background-color: #37207F !important;
  text-align: center !important;
  padding: 2.2rem !important;

  h3 {
    color: #5FF7FF;
  }

  p,
  .cross {
    color: rgba($color: #ffffff, $alpha: 0.7);
  }

  .web {
    @media(max-width: 767px) {
      display: none;
    }
  }

  .mobile {
    display: none;

    @media(max-width: 767px) {
      display: block;
    }
  }

  img {
    margin-left: 1rem;

    @media(max-width: 767px) {
      margin-bottom: 1rem;
    }
  }
}

.terms-modal {
  width: 100%;
  background: linear-gradient(#4A289C, #00092B);
  color: #FFFFFF;

  .cross {
    color: #FFFFFF;
  }

  .btn {
    display: block;
    color: #FFFFFF;
    margin: 0 auto;
  }

  .global-btn {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

button:focus,
.btn:focus {
  box-shadow: none;
  outline: 0 none;
}

:focus {
  outline: 0 none;
}

.form-control:focus {
  box-shadow: none;
  outline: 0 none;
}


.Toastify__toast--success {
  background-color: #4A289C !important;
  color: #fff;

  .Toastify__close-button {
    color: #fff;
  }
}

.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  margin-top: 1rem;
  color: #0A1A1A;
  text-align: initial !important;
  background-color: rgba($color: #5FF7FF, $alpha: 1) !important;
  border-radius: .25rem;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #5ff7ff;
  top: 4px;
}

.project-logo {
  max-width: 140px;
}

.global-btn {
  display: inline-block;
  border: 1px solid #FFFFFF;
  padding: 8px 26px 8px 26px;
  border-radius: 0.2rem;
  color: #5ff7ff;
  font-size: 1.1rem;
  transition: all .3s;
  font-family: $poppins;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.1rem;
    z-index: -2;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #5FF7FF;
    transition: all .3s;
    border-radius: 0.1rem;
    z-index: -1;
  }

  @media (max-width: 767px) {
    margin-top: 0;
  }

  &:hover {
    color: #000000;
    border: 1px solid #5ff7ff;

    &:before {
      width: 100%;
    }
  }
}

.card {
  background-color: #11062B;
  color: #FFFFFF;
  border-radius: 0.5rem;
  margin: 0 auto;
  margin-bottom: 2rem;

  &:hover {
    border: 1px solid #5FF7FF;
  }

  .card-title {
    margin-top: 1rem;
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #FFFFFF;
    display: block;

    @media(max-width: 1200px) {
      margin-left: 0.3rem;
      font-size: 1rem;
    }
  }

  .card-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-btn {
      background-color: rgba($color: #5FF7FF, $alpha: 0.4);
      color: #FFFFFF;
      border-radius: 0.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .filed {
    background: rgba($color: #0AFF00, $alpha: 0.4) 0% 0% no-repeat padding-box;
    border-radius: 0.5rem;
    color: #50EB4A;
    font-family: $poppins;
    font-weight: 600;
  }

  .raise-div {
    display: flex;
    justify-content: space-between;

    h4 {
      color: #5FF7FF;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }

    img {
      display: block;
      margin-top: 0.5rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .progress {
    background-color: rgba($color: #5FF7FF, $alpha: 0.1);
    height: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .progress-bar.bg-primary {
    background-color: #5FF7FF !important;
  }

  small {
    color: rgba($color: #FFFFFF, $alpha: 0.7);
    font-size: 0.7rem;
  }

  .mid-div {
    display: flex;
    justify-content: space-between;
    margin-top: 0.2rem;
  }

  .bottam-div {
    display: flex;
    justify-content: space-between;

    p {
      color: #5FF7FF;
      margin-top: 0.5rem;
    }
  }

  .ido-btn {
    border-radius: 0.4rem;
    display: block;
    margin: 0 auto;
    margin-top: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 1024px) {
      max-width: 185px;
    }
  }
}

.buy {
  width: auto !important;

  h3 {
    margin-top: 3rem;
  }

  p {
    width: 70%;
    margin: 0 auto;
  }

  .add-quantity {
    background: rgba(255, 255, 255, 0.16);
    padding-top: 1px;
    padding-bottom: 1px;
    margin-top: 1rem;
    border-radius: 0.5rem;

    p {
      margin-top: 1rem;
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      .btn {
        background: #5ff7ff;
        color: #0A1A1A;
        border: 0;
        padding: 0.3rem;
        width: 3rem;
        margin-top: 0.5rem;
      }

      input {
        width: auto;
        text-align: center;
        background: transparent;
        border: 0;
        color: #ffffff;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    span {
      font-size: 0.9rem;
    }
    .last-p {
      margin-bottom: 2rem;
      font-size: 0.8rem;
      span {
        font-size: 1rem;
      }
    }
}
.button {
  display: inline-block;
  width: 10rem;
  margin-left: 0.5rem;
}
.buy-btn {
  background: #5ff7ff;
        color: #0A1A1A;
        border: 0;
}
h5 {
  img {
    width: 1rem;
    margin-top: -3px;
  }
}
}