.sec-nav {
  margin-top: 2.4rem;

  @media (max-width: 767px) {
    margin-top: 3.7rem;
  }
}

.bar {
  background: #11062B 0% 0% no-repeat padding-box;

  .first-div {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    @media (max-width: 767px) {
      display: block;
    }

    .img-div {
      display: flex;
      width: 220px;
      background-color: #11062B;
      z-index: 1;

      @media (max-width: 767px) {
        display: block;
        width: 70%;
        margin: 0 auto;
      }

      img {
        margin-left: 1rem;
      }
    }

    .animated {
      margin: 0 auto;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    .animated p {
      display: inline-block;
      padding-left: 100%;
      animation: marquee 15s linear infinite;

      &:hover {
        animation-play-state: paused;
      }
    }

    @keyframes marquee {
      100% {
        transform: translate(-100%, 0);
      }
      0% {
        transform: translate(0, 0);
      }
    }

    p {
      margin-bottom: 0;
      color: #FFFFFF;

      @media (max-width: 1300px) {
        font-size: 0.9rem;
      }

      @media (max-width: 767px) {
        font-size: 0.8rem;
      }

      a {
        color: #5FF7FF;
        text-decoration: underline;
        font-family: $poppins;
      }

      span {
        font-family: $poppins;
        font-weight: 600;
      }
    }
  }
}

.dark {
  background: #000000 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  height: 6rem;
  width: 100%;

  .navbar-toggler {
    background-color: none !important;
  }
  .navbar-toggler-icon {
    background: url('../../images/toggler.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  .navbar-collapse {
    @media (max-width: 767px) {
      background: #11062B 0% 0% no-repeat padding-box;
    }
  }

  .navbar-brand {
    width: 19%;
    img {
      margin-bottom: -4px;
      width: 67%;
      margin-left: 3rem;
      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
    @media (max-width: 767px) {
      width: 54%;
      img {
        width: 100%;
      }
    }
  }



  .left {
    margin-top: -11px;
    @media (max-width: 1300px) {
      font-size: 12px;
    }

    @media (max-width: 767px) {
      margin-left: 0;
      text-align: center;
    }

    .mobile-img {
      @media (max-width: 767px) {
        width: 6%;
      }
    }

    .active {
      color: #5FF7FF !important;
    }

    .scroller {
      cursor: pointer;

    }

    .line {
      @media (max-width: 767px) {
        display: none;
      }
    }

    .nav-item {
      color: #FFFFFF;
      margin-left: 1.2rem;
      margin-top: 1.3rem;

      @media (max-width: 1340px) {
        margin-left: 1rem;
      }
      @media (max-width: 1100px) {
        margin-left: 0.5rem;
      }

      a {
        color: #FFFFFF;
        font-family: $poppins;
        font-weight: 500;

        img {
          margin-right: 7px;
          width: 1rem;
        }

      }

      .ido-img {
        display: none;

        @media (max-width: 767px) {
          display: initial;
        }

      }

      .img-a {
        margin-top: 12px;
        display: block;

        img {
          display: block;
          margin-top: -28px;
          margin-left: -8px;
        }

        span {
          display: block;
          margin-top: -7px;
        }
      }
    }
    .meta {
      margin-right: -5rem;
      img {
        width: 1.4rem !important;
      }
    }
    .coming {
      position: relative;
      top: 1.2rem;
      font-size: 0.8rem;
      padding: 1px;
      right: 5.5rem;
      background: #5FF7FF;
      color: #0A1A1A;
    }
  }

  .right {
    margin-top: 1rem;

    .address {
      color: #5FF7FF;
      margin-bottom: 0 !important;
      font-size: 0.8rem;
    }
    .react-select {
      @media (max-width: 767px) {
        width: 50%;
        margin: 0 auto;
      }
    }
    .react-select__control{
      background: none;
      border: 0;
      box-shadow: none;
    }
    .react-select__indicator {
      color: #5FF7FF;
    }
    .react-select__indicator-separator {
      display: none;
    }
    .react-select__menu, .react-select__menu-list {
      background: #4A289C;
    }
    .network {
      font-size: 0.8rem;
      img {
        width: 1.5rem;
      }
      color: #FFFFFF;
    } 
    span {
      display: block;
      color: rgba(255, 255, 255, 0.7);
      font-size: 12px;
      text-align: center;

      @media (max-width: 767px) {
        width: 49%;
        margin-top: -1rem;
        margin-bottom: 7px;
      }
    }

    .botm-span {
      @media (max-width: 767px) {
        margin-bottom: 0.5rem;
      }
    }

    .img-item {
      margin-right: 1rem !important;

      @media (max-width: 767px) {
        display: none;
      }

    }

    .btn {
      margin-bottom: 0.5rem;
      @media (max-width: 1250px) {
        padding: 5px 15px 5px 15px;
        font-size: 0.9rem;
      }
    }

    .nav-item {
      margin-right: 0.5rem;

      @media (max-width: 767px) {
        text-align: center;
      }

      .active {
        background-color: #5FF7FF !important;
        color: #0A1A1A;
        border: none;
        font-weight: 500;
      }
    }
  }
}