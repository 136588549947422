.project-detail {
  .copy {
    width: 15px;
    cursor: pointer;
  }

  .address {
    color: #5FF7FF;
    word-break: break-all;

    @media (max-width: 767px) {
      display: none !important;
    }
  }

  .mobile-address {
    display: none !important;

    @media (max-width: 767px) {
      display: block !important;
      color: #5FF7FF;
      text-align: center;
    }
  }

  .first-div {
    background: #11062B 0% 0% no-repeat padding-box;

    .row {
      width: 80%;
      margin: 0 auto;

      @media (max-width: 767px) {
        width: 90%;
      }
    }

    .top-div {
      display: flex;
      margin-bottom: 1rem;

      @media (max-width: 767px) {
        display: initial;
      }

      .heading-div {
        display: flex;
        align-items: center;
        color: #FFFFFF;
        margin-top: 2rem;
        width: 100%;
      }
      .name-div {
        width: 70%;
        margin-left: 20px;
      }

      .img-div {
        display: flex;

        img {
          margin-right: 10px;
        }

        @media (max-width: 767px) {
          margin-top: 0.5rem;
          width: 100%;
          margin-bottom: 1rem;
        }

      }
    }

    p {
      color: rgba($color: #ffffff, $alpha: 0.6);

      @media (max-width: 767px) {
        text-align: center;
      }
    }

    .mid-div {
      display: flex;
      justify-content: space-between;
      width: 51%;
      margin-top: 2rem;

      @media (max-width: 767px) {
        width: 78%;
        margin: 0 auto;
        margin-top: 2rem;
      }

      small {
        display: block;
        color: rgba($color: #ffffff, $alpha: 0.6);
        margin-bottom: 1rem;
      }

      h4 {
        color: #5FF7FF;
      }
    }

    .bottam-div {
      display: flex;
      justify-content: space-between;
      color: rgba($color: #ffffff, $alpha: 0.6);
      margin-top: 2rem;
      margin-bottom: 1rem;

      p {
        color: #5FF7FF;
        margin-top: 0.6rem;
      }
    }
    .address {
      color: #5FF7FF !important;
    }
    .btn {
      display: inline-block;
      border: 1px solid #FFFFFF;
      padding: 10px 30px 10px 30px;
      border-radius: 0.4rem;
      margin-bottom: 2rem;
      color: #5ff7ff;
      margin-top: 2rem;
      font-size: 1.1rem;
      transition: all .3s;
      margin-left: 0.5rem;
      font-family: $poppins;
      font-weight: 500;
      position: relative;
      overflow: hidden;
      z-index: 1;

      @media (max-width: 767px) {
        margin: 0 auto;
        display: block;
        margin-bottom: 1rem;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0.2rem;
        z-index: -2;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: #5FF7FF;
        transition: all .3s;
        border-radius: 0.2rem;
        z-index: -1;
      }

      &:hover {
        color: #000000;

        &:before {
          width: 100%;
        }
      }
    }

    .box {
      background: linear-gradient(#4A289C, #11062B, );;
      width: 90%;
      margin: 3rem 0px 0 auto;
      border-radius: 6px;
      padding: 25px;

      @media (max-width: 767px) {
        width: auto;
      }

      .refresh {
        float: right;
        cursor: pointer;
        transition-duration: 1s;
      }

      .first-p {
        margin-top: 2rem;
      }

      p {
        font-size: 18px;
      }

      .time {
        color: #FFFFFF;
        font-size: 30px;
        font-family: $poppins;
        font-weight: 600;

        @media (max-width: 767px) {
          font-size: 20px;
          text-align: center;
          display: block;
        }
      }

      span,
      small {
        font-size: 16px;
        color: rgba($color: #ffffff, $alpha: 0.8);

        @media (max-width: 767px) {
          font-size: 14px;
        }
      }

      .amount-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;

        @media (max-width: 767px) {
          text-align: center;
          display: block;
        }

        p {
          color: #5FF7FF;
          font-size: 25px;
          font-family: $poppins;
          font-weight: 600;
          margin-bottom: 0.3rem;
        }
      }

      .claim-div {
        display: flex;
        justify-content: space-between;
        width: 80%;
        margin-bottom: 1rem;
        margin-top: 1rem;

        @media (max-width: 767px) {
          width: auto;
        }

        p {
          color: #ffffff;
          font-size: 30px;

          @media (max-width: 767px) {
            font-size: 16px;
          }
        }
      }

      .progress {
        background-color: rgba($color: #5ff7ff, $alpha: 0.1);
        margin-top: 1rem;

        .progress-bar {
          background-color: #5ff7ff;
        }
      }

      .progress-div {
        display: flex;
        justify-content: space-between;
        margin-top: 0.5rem;

        p {
          
          font-size: 1vw !important;
          @media (max-width: 767px) {
            font-size: 0.8rem !important;
          }
        }

        span {
          color: #5ff7ff;
        }
      }
    }
  }

  .last-div {
    background-color: #000000;

    .row {
      width: 80%;
      margin: 0 auto;

      @media (max-width: 767px) {
        width: 90%;
      }
    }

    .heading-div {
      display: flex;
      margin-top: 2rem;

      @media (max-width: 767px) {
        width: auto;
      }

      p {
        color: rgba($color: #ffffff, $alpha: 0.6);
        line-height: 2.5;
      }

      .active {
        color: #5FF7FF;
        line-height: 2.5;
        border-bottom: 5px solid #5FF7FF;
      }
    }

    p {
      color: #FFFFFF;
      margin-top: 1rem;
      margin-bottom: 2rem;

      @media (max-width: 767px) {
        text-align: center;
      }
    }

    .box {
      background: linear-gradient(to bottom, #4A289C, #4A289C);;
      mix-blend-mode: screen;
      border-radius: 20px;
      padding: 2rem;
      margin-bottom: 2rem;
      padding-bottom: 3rem;

      @media (max-width: 767px) {
        margin-bottom: 0.5rem;
        text-align: center;
      }

      .mid-div {
        display: flex;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 100%;
      }

      p {
        color: rgba($color: #ffffff, $alpha: 0.8);
        margin-bottom: 0.3rem;
        margin-top: 0;
      }

      span {
        color: #FFFFFF;
        font-size: 25px;
        margin-bottom: 0.5rem;
        display: block;
      }
    }

    .token-p {
      margin-left: 2rem;

      @media (max-width: 767px) {
        margin-left: 0;
      }
    }

    .box2 {
      margin-left: 2rem;
      padding-bottom: 2.5rem;

      @media (max-width: 767px) {
        margin-left: 0;
      }

      span {
        font-size: 22px;
      }

      .mid-div {
        width: 60%;

        @media (max-width: 767px) {
          width: auto;
        }

        div {
          width: 50%;
        }
      }

      .address {
        color: #5FF7FF !important;
        @media (max-width: 767px) {
          font-size: 11px;
        }
      }
    }

    .hide {
      visibility: hidden;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}
