// *{
//   border: 1px solid gray;
// }
.home {

  .top-div {
    background: #000000 0% 0% no-repeat padding-box;
    text-align: center;
    margin-top: 4rem;
    color: #FFFFFF;
    background-image: url('../../images/back.gif');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @media (max-width: 767px) {
      background-image: url('../../images/mobile-back.gif');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    margin-top: 6rem;

    }

    h1 {
      margin-top: 7rem;
      margin-bottom: 2rem;
      font-family: $poppins;
      font-weight: 600;

      @media (max-width: 767px) {
        display: none;
      }
    }

    a {
      color: #5ff7ff;
    }

    .cc {
      position: absolute;
      top: 75%;
      width: 9%;
      left: 37.5%;

      @media (max-width: 767px) {
        top: 67%;
        width: 30%;
        left: 33%;
      }
    }

    .m-head {
      display: none;

      @media (max-width: 767px) {
        display: block;
        margin-top: 5rem;
        margin-bottom: 0rem;
        color: #5ff7ff;
        font-family: $poppins;
        font-weight: 600;
      }
    }

    .mm-head {
      display: none;

      @media (max-width: 767px) {
        display: block;
        margin-top: 0rem;
        margin-bottom: 2rem;
        font-family: $poppins;
        font-weight: 600;
      }
    }

    span {
      color: #5FF7FF;
    }

    p {
      width: 45%;
      margin: 0 auto;
      font-family: $poppins;
      font-weight: 400;
      font-size: 16px;

      @media (max-width: 767px) {
        width: 90%;
      }
    }
    .address-div {
      p {
        font-size: 13px;
      }
      img {
        margin-left: 1rem;
        cursor: pointer;
      }
    }

    .btn {
      .wh2 {
        display: none;
      }

      .ap2 {
        display: none;
      }

      @media (max-width: 767px) {
        margin-left: 0;
      }

      img {
        margin-right: 6px;
      }
      display: inline-block;
      border: 1px solid #FFFFFF;
      padding: 12px 0 12px 0;
      border-radius: 28px;
      margin-bottom: 5rem;
      color: #5ff7ff;
      margin-top: 5rem;
      font-size: 1.1rem;
      width: 15%;
      transition: all .3s;
      font-family: $poppins;
      font-weight: 500;
      position: relative;
      overflow: hidden;
      z-index: 1;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        border-radius: 10rem;
        z-index: -2;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: #5FF7FF;
        transition: all .3s;
        border-radius: 10rem;
        z-index: -1;
      }

      &:hover {
        color: #000000;

        .wh {
          display: none;
        }

        .wh2 {
          display: initial;
        }

        .ap {
          display: none;
        }

        .ap2 {
          display: initial;
        }

        &:before {
          width: 100%;
        }
      }
    }

    .app {
      @media (max-width: 767px) {
        margin-bottom: 1rem;
        width: 45%;
      }
    }

    .vie {
      width: 20%;
      img {
        margin-top: -6px
      }
      @media (max-width: 767px) {
        margin-top: 1rem;
        width: 70%;
      }
    }
  }

  .sale-div {
    background: #000000 0% 0% no-repeat padding-box;
    text-align: center;

    .p {
      display: block;
      color: #FFFFFF;
      margin-bottom: 3rem;
      font-family: $poppins;
      font-weight: 300;
      font-style: italic;
      margin-top: 5rem;
    }
    .hide {
      visibility: hidden;
    }
    .power {
      margin-bottom: 6rem;
      display: flex;
      margin: 0 auto;
      justify-content: space-evenly;
      width: 66%;
      margin-bottom: 5rem;

      div {
        display: none;
      }

      @media (max-width: 767px) {
        width: 100%;
        display: block;

        .hide-img {
          display: none;
        }

        div {
          display: flex;
          margin-top: 2rem;
          justify-content: space-around;
        }
      }

      img {
        height: 10%;
      }
    }

    sub {
      vertical-align: super;
    }

    h6 {
      color: greenyellow;
      font-size: 18px;
      margin-top: 1rem;
      @media (max-width: 1400px) {
        font-size: 17px;
      }
    }

    .m-step {
      display: none;
      @media (max-width: 767px) {
        display: block;
        margin: 0 auto;
        width: 82%;
        margin-bottom: 2rem;
      }
    }

    .last-price {
      margin-left: 0.8rem;
    }

    .scroll {
      display: none;

      @media (max-width: 767px) {
        display: initial;
      }

      ul {
        list-style: none;
        display: flex;
        padding: 0;

        li {
          margin-left: 0.6rem;

          .heading-box {
            padding: 1.6rem;
            // margin-left: 1rem;
            background-color: #142C2C;
            color: #5ff7ff;
            border-radius: 10px;

            // margin-top: 1rem;
            h5 {
              font-family: $poppins;
              font-weight: 500;
              font-size: 18px;
            }
          }

          .public-box {
            background: #142C2C;
            padding: 0.5rem 0.1rem 1px 0.12rem;
            // margin-left: 1rem;
            // margin-top: 1rem;
            border-radius: 10px;
            color: #5ff7ff;

            p {
              margin-bottom: 0.2rem;
              font-size: 15px;
            }
          }

          .round-box {
            background-color: #142C2C;
            color: rgba(255, 255, 255, 0.9);
            // padding: 0.5rem 0.1rem 0.5rem 0.12rem;
            padding: 1.7rem;
            // margin-left: 1rem;
            border-radius: 10px;

            // margin-top: 5px;
            p {
              margin-bottom: 0.2rem;
              font-size: 14px;
            }
          }
        }
      }
    }

    .col-md-8 {
      margin: 0 auto;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .web {
      .unsold-span {
        color: rgba($color: #FFFFFF, $alpha: 0.8);
    display: block;
    margin-bottom: 3rem;
    font-family: $poppins;
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
      }
      @media (max-width: 767px) {
        display: none;
      }

      .heading-box {
        padding: 1.6rem;
        margin-left: 1rem;
        background-color: #142C2C;
        color: #5ff7ff;
        border-radius: 10px;
        margin-top: 1rem;

        h5 {
          font-family: $poppins;
          font-weight: 500;
          font-size: 18px;
        }
      }

      .public-box {
        background: #142C2C;
        padding: 0.5rem 0.1rem 1px 0.12rem;
        margin-left: 1rem;
        margin-top: 1rem;
        border-radius: 10px;
        color: #5ff7ff;

        p {
          margin-bottom: 0.2rem;
          font-size: 15px;
        }
      }

      .round-box {
        background-color: #142C2C;
        color: rgba(255, 255, 255, 0.9);
        padding: 0.5rem 0.1rem 0.5rem 0.12rem;
        margin-left: 1rem;
        border-radius: 10px;
        margin-top: 5px;

        p {
          margin-bottom: 0.2rem;
          font-size: 14px;
        }
      }
    }

    .col-md-3 {
      display: flex;
    }

    .heading {
      color: #5FF7FF;
      margin-top: 4rem;
      font-family: $poppins;
      font-weight: 500;
      margin-bottom: 2rem;
    }

    hr {
      width: 82%;
      /* margin-bottom: 1rem; */
      border: 0;
      border-top: 1px solid gray;
    }

    .first-hr {
      border: 1px solid #5ff7ff;
    }

    .image {
      margin-left: -22px;
    }

    .image4 {
      margin-left: -30px;
    }

    .last {
      width: 73%;
    }

    .card {
      background: #142C2C 0% 0% no-repeat padding-box;
      border-radius: 10px;
      color: #FFFFFF;
      margin-left: 1rem;
      margin-top: 0.5rem;
      margin-bottom: 1rem;

      @media (max-width: 767px) {
        margin-left: 0;
        width: 18rem;
      }

      h5 {
        font-family: $poppins;
        font-weight: 300;
      }

      .time {
        color: red;
        display: block;
        margin-bottom: 1rem;
      }

      .total {
        font-size: 12px;
        display: block;
      }

      .mid-div {
        display: flex;
        justify-content: space-around;

        .sub-mid {
          margin-right: -17px;
        }

        @media (min-width: 1600px) {
          width: 80%;
          margin: 0 auto;
        }
      }

      .change {
        color: #50EB4A;
        margin-bottom: 0;
        font-family: $poppins;
        font-weight: 600;
      }

      .h5 {
        color: #5FF7FF;
        margin-bottom: 0;
      }

      .h {
        color: #5FF7FF;
        margin-bottom: 0;
      }

      small {
        font-family: $poppins;
        font-weight: 300;
        font-size: 0.6rem;
      }

      p {
        font-family: $poppins;
        font-weight: 600;
        font-size: 0.9rem;

        @media (max-width: 1340px) {
          font-size: 0.8rem;
        }
      }

      .progress {
        background-color: #235556;
        height: 0.5rem;
      }

      .sec-progress {
        height: 0.5rem;

        .progress-bar {
          background-color: #5FF7FF;
        }
      }

      .first-progress {
        height: 0.5rem;

        .progress-bar {
          background-color: #50EB4A;
        }
      }

      .btn {
        background: #142C2C 0% 0% no-repeat padding-box;
        border-radius: 28px;
        width: 80%;
        margin-top: 1rem;
        font-family: $poppins;
        font-weight: 600;
        color: #2B6A6D;
        border: 1px solid #2B6A6D;
      }

      .change-btn {
        background-color: #000000;
        border: 1px solid #5FF7FF;
        color: #5FF7FF;

        &:hover {
          background-color: #5ff7ff;
          color: #000000;
        }
      }

      .available {
        background-color: #000000;
        color: #5FF7FF;
        border: 1px solid #5ff7ff;
        width: 69%;
        margin: 0 auto;
        display: block;
        font-size: 15px;

        @media (min-width: 1555px) {
          width: 50%;
        }

        @media (max-width: 767px) {
          width: 47%;
        }

        // @media (min-width: 1700px) {
        //   width: 54%;
        // }

      }

      .ful {
        color: #28a745 !important;
        border: 1px solid #28a745;
      }
      .soon {
        width: 95%;
        margin-bottom: 2.6rem;
        background-color: #142C2C;

        @media (min-width: 1555px) {
          width: 67%;
        }

        @media (max-width: 767px) {
          width: 61%;
        }
      }

      .first-btn {
        background: #50EB4A 0% 0% no-repeat padding-box;
        border-radius: 28px;
        width: 80%;
        margin-top: 1rem;
        color: #0B1414;

      }
    }

    .sec-card {
      background-color: #000000;
      border: 1px solid #142C2C;
      margin-top: 0.5rem;
    }
  }

  .exchange {
    background: #0C1414 0% 0% no-repeat padding-box;
    text-align: center;

    h5 {
    color: #ffffff;
    font-family: $poppins;
    font-weight: 300;
    font-style: italic;
    margin-top: 3rem;
    margin-bottom: 3rem;
      
    }
    .box {
      width: 61%;
      margin: 0 auto;
      @media (max-width: 767px) {
       display: none;
      }
      .uni-box {
        cursor: pointer;
        background-color: #ffffff;
        color: #070707;
        font-family: $poppins;
        font-weight: 500;
        padding: 9px 8px 1px 3px;
        font-size: 14px;
        border-radius: 10px;
        margin-bottom: 3rem;
        margin-left: 1.3rem;
        img {
          width: 68%;
        }
        p {
          margin-bottom: 0.5rem;
          margin-top: 0.5rem;
        }
        @media (max-width: 1335px) {
          font-size: 13px;
         }
      }

      .hot {
        background: #1CA18D;
        color: #FFFFFF;
        img {
          width: 45%;
        }
      }
      .pro {
        background: #4231C8;
        color: #FFFFFF;
        img {
          width: 60%;
        }
      }
      .comming-box {
        background: rgba(95, 247, 255, 0.2) 0% 0% no-repeat padding-box;
        border-radius: 10px;
        margin-bottom: 3rem;
        padding: 1.6rem 1rem 1.7rem 1rem;
         margin-left:  1.3rem;

        img {
          width: 70%;
        }
      }
    }

    .mobile-box {
      display: none;
      @media (max-width: 767px) {
        display: flex;
      justify-content: space-around;

       }
      .uni-box {
        font-size: 12px;
        border-radius: 10px;
        font-family: $poppins;
        font-weight: 500;
        margin-bottom: 1rem;
        color: #000000;
        padding: 4px;
        background-color: #ffffff;
        p {
          width: 60%;
          margin: 0 auto;
        }
      }
      .hot {
        background: #1CA18D;
        color: #FFFFFF;
        img {
          width: 45%;
        }
      }
      .pro {
        background: #4231C8;
        color: #FFFFFF;
        img {
          width: 60%;
        }
      }
      .comming-box {
        background: rgba(95, 247, 255, 0.2) 0% 0% no-repeat padding-box;
        border-radius: 10px;
        margin-bottom: 1rem;
        padding: 1.5rem 0.5rem 1.5rem 1.5rem;
            }
    }
    .bottom {
      margin-bottom: 3rem;
    }
  }
  .card-div {
    background: #000000 0% 0% no-repeat padding-box;
    // border-bottom: 1px solid #324444;
    .sub-card {
      // filter: blur(8px);
      // -webkit-filter: blur(10px);
      background: #000000 0% 0% no-repeat padding-box;
      @media (max-width: 767px) {
        // margin-top: 11rem;
        padding: 5px;
      }
    }

    .hide-card {
      @media (max-width: 767px) {
        display: none;
      }
    }

    // .comming {
    //   z-index: 1;
    //   position: relative;
    //   text-align: center;
    //   color: #FFFFFF;
    //   top: 20rem;

    //   img {
    //     @media (max-width: 767px) {
    //       width: 18% !important;
    //     }
    //   }

    //   h5 {
    //     font-family: $poppins;
    //     font-weight: 500;
    //   }
    // }

    .number-div {
      z-index: 2;
      position: relative;
      text-align: center;
      font-family: "Poppins";
      top: 25rem;
      ;
      h1 {
        font-weight: 600;
        color: #5ff7ff;
      }

      span {
        display: block;
        width: 9rem;
        margin: 0 auto;
        height: 3px;
        background-color: #5ff7ff;
      }

      p {
        margin-top: 1rem;
        color: #FFFFFF;
        font-weight: 500;
      }
    }

    .heading {
      text-align: center;
      color: #5FF7FF;
      margin-bottom: 3rem;
      margin-top: 3rem;
      font-family: $poppins;
      font-weight: 500;
    }
    .card {
      background: #000000 0% 0% no-repeat padding-box;
      color: #FFFFFF;
      text-align: initial;
      border: 1px solid #5FF7FF;
      border-radius: 10px;
      margin-top: 2rem;
      margin-bottom: 5rem;
      margin-left: 3rem;
      @media (max-width: 767px) {
       margin-left: 0;
      }
      .top-btn {
        float: right;
       background: #0C1414 0% 0% no-repeat padding-box;
       color: #5FF7FF;
        border-radius: 1.5rem;
      }
      .ido-btn {
        border: 1px solid rgba($color: #5ff7ff, $alpha: 0.5);
        display: block;
        border-radius: 1.5rem;
        margin: 0 auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        color: #FFFFFF;
        &:hover {
          background-color: #5FF7FF;
          color: #112626;
        }
      }

      @media (max-width: 767px) {
        width: 100%;
      }

      .card-title {
        margin-top: 0.5rem;
      }

      h4 {
        color: #5FF7FF;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
      }

      small {
        color: rgba($color: #FFFFFF, $alpha: 0.5);
      }

      .progress {
        height: 0.5rem;
        background: rgba($color: #F3F3F3, $alpha: 0.05) 0% 0% no-repeat padding-box;
      }

      .progress-bar {
        background: #5FF7FF 0% 0% no-repeat padding-box;
        border-radius: 10px;
        height: 0.5rem;
      }

      .mid-div {
        display: flex;
        justify-content: space-between;
      }

      .botam-div {
        display: flex;
        justify-content: space-between;

        p {
          color: #5FF7FF;
        }
      }
    }

    .col-hide {
      @media (max-width: 767px) {
        display: none;
      }
    }
    .sec-card {
      border: 1px solid #0C1414;
       background: #0C1414 0% 0% no-repeat padding-box;
    .hide {
      visibility: hidden;
    }
   
    }
    .span-div {
      display: flex;
      justify-content: center;
      span {
        width: 10px;
        height: 10px;
        display: block;
        margin-left: 5px;
        border: 1px solid #5FF7FF;
        border-radius: 1rem;

      }
      .first {
        width: 25px;
        background-color: #5FF7FF;
      }
    }
  }
  .featured {
    background: #000000 0% 0% no-repeat padding-box;
    text-align: center;

    h5 {
    color: #ffffff;
    font-family: $poppins;
    font-weight: 300;
    font-style: italic;
    margin-top: 3rem;
    margin-bottom: 3rem;
      
    }
    .row {
      margin-bottom: 4rem;
      .pad {
        @media (max-width: 767px) {
          padding-left: 0.5rem;
          
        }
      }
      .hide {
        @media (max-width: 767px) {
        display: none;
        }
      }
    }
  }
  .detail-div {
    background: #0C1415 0% 0% no-repeat padding-box;
    padding: 4rem 0 8rem 0;
    border-top: 1px solid #324444;
    border-bottom: 1px solid #324444;

    h5 {
      text-align: center;
      color: #5FF7FF;
    }

    img {
      margin-bottom: 1rem;
    }

    &:hover .animate__animated {
      visibility: visible;
      -webkit-animation-name: fadeInUp;
      animation-name: fadeInUp;
    }

    .main-detail {
      display: flex;
      width: 60%;
      margin: 0 auto;
      justify-content: space-between;
      font-family: $poppins;
      font-weight: 400;

      @media (max-width: 767px) {
        display: block;
        width: 100%;
      }

      h4 {
        margin-bottom: 1.5rem;
        font-weight: 600;
        font-size: 1.3rem;

      }

      .image {
        margin-bottom: 1.8rem;
      }

      .image2 {
        margin-top: 1rem;
        margin-bottom: 1.2rem;
      }

      p {
        width: 88%;
        font-size: 15px;
        margin: 0 auto;
      }

      div {
        color: #FFFFFF;
        width: 35%;
        text-align: center;
        margin-top: 5rem;

        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }

  }

  .partners {
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    
    h5 {
      color: #FFFFFF;
    text-align: center;
    font-family: $poppins;
    font-weight: 600;
    margin-top: 3rem;
    margin-bottom: 3rem;
    }
    .imgg {
      margin: 0 auto;
      width: 78%;
      justify-content: space-evenly;
      display: flex;
      margin-bottom: 3rem;
      @media (max-width:667px) {
        width: 100%;;
      }
      .hide {
        @media (max-width:667px) {
          display: none;
        }
      }
    }

    .imgg2 {
      display: none;
      margin-bottom: 3rem;
      @media (max-width:667px) {
        display: flex;
        justify-content: space-evenly;
      }
    }
    img {
      @media (max-width:667px) {
        width: 38%  ;
      }
    }
  }
  .project-div {
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    // opacity: 0.97;
    color: #FFFFFF;

    .heading {
      color: #5FF7FF;
      margin-top: 5rem;
      text-align: center;
      font-family: $poppins;
      font-weight: 500;
      margin-bottom: 2rem;
      // margin-left: 4rem;

      @media (max-width: 767px) {
        margin-left: 0;
      }
    }

    p {
      font-size: 15px;
      font-family: $poppins;
      font-weight: 400;
    }

    .col-md-8 {
      margin: 0 auto;

      @media (max-width: 767px) {
        display: none;
      }

      .steple-div {
        display: flex;
        position: relative;
        top: 2.2rem;
        z-index: 1;

        .mid-steple {
          margin-top: -35px;
        }

        .colrful {
          display: block;
          width: 17.5rem;
          height: 3px;
          background-color: #5FF7FF;
          margin-top: 0.8rem;

        }

        .colrless {
          display: block;
          width: 18rem;
          height: 3px;
          background-color: #324444;
          margin-top: 0.8rem;
        }
      }

      .year {
        display: flex;
        justify-content: space-between;
        position: relative;
        top: 0.5rem;
        left: -11px;
        font-family: $poppins;
        font-weight: 600;

        .first-para {
          color: #50EB4A;
        }

        h5 {
          top: -11px;
          position: relative;
          left: 9px;
          color: #5FF7FF;
        }

        .date {
          position: relative;
          top: 1rem;
        }

        .d {
          left: -5px;

          @media (min-width: 1600px) {
            left: 1px;
          }
        }

        .d4 {
          left: 1.8rem;
        }

        .d5 {
          left: 1.2rem;
        }
      }
    }

    .web {
      @media (max-width: 767px) {
        display: none;
      }

    }

    .mobile-view {
      display: none;

      h5 {
        margin-top: 1rem;
        color: #5FF7FF;
        margin-left: 5px;
      }

      .first-para {
        color: #50EB4A;
      }

      .d-l {
        margin-top: 9px;
      }

      .last {
        margin-top: -22px;
      }

      .hide {
        visibility: hidden;
      }

      @media (max-width: 767px) {
        display: flex;
      }

      .m-steple {
        width: 80%;
        height: 66rem;
        margin-top: 2px;

      }
    }

    .card {
      background: var(--unnamed-color-5ff7ff) 0% 0% no-repeat padding-box;
      background: #142C2C 0% 0% no-repeat padding-box;
      border-radius: 20px;
      opacity: 0.8;
      border-radius: 10px;
      color: #FFFFFF;
      margin-left: 1rem;
      margin-top: 1rem;
      margin-bottom: 4rem;
      font-family: $poppins;
      font-weight: 400;
      font-size: 0.9rem;

      img {
        margin-right: 10px;
        height: 10%;
        margin-top: 4px;
        width: 6%;

        // width: 10%;
        @media (max-width: 767px) {
          height: 10%;
          // width: 6%;
        }

      }

      .card-body {
        div {
          display: flex;
          margin-bottom: 0.3rem;
        }
      }

      @media (max-width: 767px) {
        margin-left: 0;
        margin-bottom: 2.5rem;
      }
    }
  }

  .work-div {
    background: #0C1415 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    padding: 5rem 0 3rem 0;
    font-family: $poppins;
    font-weight: 400;

    @media (max-width: 767px) {
      text-align: center;
    }

    h5 {
      margin-top: 7rem;
      font-family: $poppins;
      font-weight: 600;
      font-size: 22px;

      @media (max-width: 767px) {
        margin-top: 0rem;

      }
    }

    p {
      font-size: 15px;
      font-family: $poppins;
      font-weight: 400;

      @media (max-width: 767px) {
        width: 93%;
        margin: 0 auto;
      }
    }

    .video {
      margin-top: 6rem;
      margin-bottom: 7rem;
      margin-left: 2rem;

      @media (max-width: 767px) {
        margin: 0;
      }
    }

    .btn {

      .dark-icon {
        display: none;
      }

      display: inline-block;
      border: 1px solid #FFFFFF;
      padding: 12px 0 12px 0;
      border-radius: 28px;
      margin-bottom: 2rem;
      color: #5ff7ff;
      margin-top: 1rem;
      // font-size: 1rem;
      width: 50%;
      // letter-spacing: .15rem;
      transition: all .3s;
      position: relative;
      overflow: hidden;
      z-index: 1;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        border-radius: 10rem;
        z-index: -2;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: #5FF7FF;
        transition: all .3s;
        border-radius: 10rem;
        z-index: -1;
      }

      &:hover {
        color: #000000;

        .dark-icon {
          display: initial;
        }

        .light-icon {
          display: none;
        }

        &:before {
          width: 100%;
        }
      }
    }

    // .btn {
    //   border: 1px solid #FFFFFF;
    //   color: #FFFFFF;
    //   border-radius: 28px;
    //   margin-bottom: 2rem;
    //   margin-top: 2rem;
    //   width: 50%;
    //   background: #0C1415 0% 0% no-repeat padding-box;

    //   @media (max-width: 767px) {
    //     margin-bottom: 6rem;

    //   }

    //   &:hover {
    //     background-color: #5FF7FF;
    //     color: #000000;
    //   }

    // }
  }

  .marketing-div {
    background: #000000 0% 0% no-repeat padding-box;
    padding: 3rem 0 3rem 0;

    .nft-img {
      display: block;
      margin: 0 auto;
      width: 53%;

      @media (max-width: 767px) {
        width: 90%;
      }
    }

    .sub-div {
      width: 60%;
      margin: 0 auto;
      margin-top: 2rem;
      border: 1px solid black;
      color: #FFFFFF;
      box-shadow: 8px 7px 12px #00000000;
      display: flex;
      justify-content: space-evenly;
      font-family: $poppins;
      font-weight: 400;

      @media (max-width: 767px) {
        width: 89%;
        display: block;
      }

      .mobile-view {
        display: none;

        @media (max-width: 767px) {
          display: block;
          margin: 0 auto;
          margin-bottom: 3rem;
        }
      }

      div {
        width: 41%;

        @media (max-width: 767px) {
          width: 100%;
        }

        h5 {
          margin-bottom: 1rem;
          margin-top: 7rem;
          font-weight: 600;
          font-size: 22px;

          @media (max-width: 767px) {
            text-align: center;
          }
        }

        p {
          margin-bottom: 2rem;
          font-size: 0.9rem;

          @media (max-width: 767px) {
            text-align: center;
          }

          // width: 80%;
          @media (min-width: 1500px) {
            width: 87%;
          }
        }

        .hide-p {
          @media (max-width: 767px) {
            display: none;

          }
        }

        span {
          display: block;
          text-align: center;
          // margin-bottom: 1rem;
        }

        img {
          display: block;
          // margin: 0 auto;
          margin-bottom: 2rem;
          display: block;
          margin-bottom: 2rem;
          margin-top: 1rem;
          width: 100%;
          // margin-top: 2rem;
          // width: 83%;
          // @media (min-width: 1500px) {
          //   margin-top: 3rem;
          // }

          @media (max-width: 767px) {
            margin: 0 auto;
            margin-top: 2rem;
          }
        }
      }

      .mobile {
        // width: 72%;
        width: 26%;
        height: 38%;

        @media (min-width: 1500px) {
          width: 18%;
        }

        @media (max-width: 767px) {
          // width: 100%;
          display: none;
        }

      }
    }
  }

  .token {
    background-image: url('../../images/home/dark.svg');
    background-position: center;
    text-align: center;
    color: #FFFFFF;


    h5 {
      font-family: $poppins;
      font-weight: 600;
      font-size: 22px;
      z-index: 1;
      position: relative;
      top: 2.5rem;
    }

    .heading {
      margin-bottom: 4rem;
      margin-top: 4rem;
      font-family: $poppins;
      font-weight: 500;
      font-size: 1.25rem;
      color: #5FF7FF;
      position: initial;
    }

    .first {
      top: 5.8rem;
    }

    .public {
      margin-top: 3.3rem;
      margin-bottom: 2.7rem;
    }

    img {
      display: block;
      margin: 0 auto;
      margin-bottom: 4rem;
      width: 90%;

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 0;
      }

    }

    .last-token {
      width: 80%;
    }

    .tok {
      margin-top: 4rem;
    }

    .sub-token {
      display: flex;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .imYUvI {
      display: none;

      @media (max-width: 767px) {
        display: initial;
      }

      h5 {
        position: initial;
      }

      .btn {
        color: #5FF7FF;

        &:focus {
          box-shadow: none;
          outline: 0 none;
        }
      }

      .euKzJn {
        top: 10rem;
      }

      .aoTUt {
        right: -179px;
      }

      .dHQcjo {
        left: -173px;
      }
    }

    // .sub-token {
    //   display: flex;
    //   justify-content: space-around;
    //   width: 62%;
    //   margin: 0 auto;
    //   text-align: initial;
    //   font-family: $poppins;
    //   font-weight: 500;

    //   @media (max-width: 767px) {
    //     display: block;
    //     width: auto;
    //   }

    //   .circle-div {
    //     display: flex;
    //     margin-top: 5rem;
    //   }

    //   ul {
    //     list-style: none;
    //     margin-top: 5rem;

    //     li {
    //       display: flex;
    //       margin-top: 1rem;

    //       span {
    //         display: block;
    //         width: 10px;
    //         height: 10px;
    //         margin-right: 9px;
    //         margin-top: 9px;
    //       }
    //     }

    //     .sale-li {
    //       color: #5FF7FF;

    //       span {
    //         margin-right: 0;
    //         margin-left: 10px;
    //         background-color: #5FF7FF;
    //       }
    //     }

    //     .first {
    //       color: #38FF12;

    //       span {
    //         background-color: #38FF12;
    //       }
    //     }

    //     .sec {
    //       color: #FF00E3;

    //       span {
    //         background-color: #FF00E3;
    //       }
    //     }

    //     .third {
    //       color: #F25921;

    //       span {
    //         background-color: #F25921;
    //       }
    //     }

    //     .fourth {
    //       color: #FEB800;

    //       span {
    //         background-color: #FEB800;
    //       }
    //     }

    //     .fifth {
    //       color: #1900A0;

    //       span {
    //         background-color: #1900A0;
    //       }
    //     }

    //     .sixth {
    //       color: #9600FF;

    //       span {
    //         background-color: #9600FF;
    //       }
    //     }

    //     .seven {
    //       color: #FFF100;

    //       span {
    //         background-color: #FFF100;
    //       }
    //     }


    //   }
    // }

    // .distribution-div {
    //   width: 50%;
    //   margin: 0 auto;
    //   margin-bottom: 4rem;

    //   @media (max-width: 767px) {
    //     width: 98%;
    //   }

    //   div {
    //     display: flex;
    //     justify-content: space-between;
    //     // margin-top: 2rem;
    //   }

    //   .private {
    //     background-color: rgba(95, 247, 255, 0.3);
    //     border-radius: 9px;
    //     margin-bottom: 1rem;

    //     .progress-bar {
    //       background: #5FF7FF 0% 0% no-repeat padding-box;
    //       border-radius: 9px;

    //     }

    //   }

    //   .public {
    //     background-color: rgba(56, 255, 18, 0.3);
    //     border-radius: 9px;
    //     margin-bottom: 1rem;

    //     .progress-bar {
    //       background: #38FF12 0% 0% no-repeat padding-box;
    //       border-radius: 9px;

    //     }

    //   }

    //   .launch {
    //     background: rgba(242, 89, 33, 0.3);
    //     border-radius: 9px;

    //     .progress-bar {
    //       background: #F25921 0% 0% no-repeat padding-box;
    //       border-radius: 9px;
    //     }

    //   }
    // }

    // .line {
    //   display: block;
    //   width: 3px;
    //   border-radius: 5px;
    //   height: 18px;
    //   background-color: #ffff;
    // }

    // .divider {
    //   position: absolute;
    //   left: 41.2%;
    //   top: 81%;

    //   @media (min-width: 1600px) {
    //     top: 80%;
    //   }

    //   @media (max-width: 767px) {
    //     left: 40.7%;
    //     top: 89.5%;
    //   }

    //   small {
    //     margin-left: -6px;
    //     color: #5ff7ff;
    //   }

    // }

    // .third-divider {
    //   position: absolute;
    //   left: 92.5%;
    //   top: 81%;

    //   @media (min-width: 1600px) {
    //     top: 80%;
    //   }

    //   @media (max-width: 767px) {
    //     left: 91.7%;
    //     top: 89.5%;
    //   }

    //   small {
    //     margin-left: -6px;
    //     color: #FF00E3;
    //   }


    // }

    // .sec-divider {
    //   position: absolute;
    //   left: 75.5%;
    //   top: 81%;

    //   @media (min-width: 1600px) {
    //     top: 80%;
    //   }

    //   @media (max-width: 767px) {
    //     left: 74.7%;
    //     top: 89.5%;
    //   }

    //   small {
    //     margin-left: -6px;
    //     color: #38FF12
    //   }

    // }

    // .progress-img {
    //   width: 93%;
    // }

    // .round-div {
    //   display: flex;
    //   justify-content: space-between;
    //   width: 67%;
    //   margin: 0 auto;
    //   margin-bottom: 5rem;

    //   @media (max-width: 767px) {
    //     width: 85%;
    //     ;
    //   }
    // }

  }

  .team {
    background: #000000 0% 0% no-repeat padding-box;
    text-align: center;

    h5 {
      margin-top: 1rem;
      font-family: $poppins;
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 3.6px;
      color: #5FF7FF;

      @media (max-width: 767px) {
        font-size: 14px;
        letter-spacing: 2.6px;
        margin-bottom: 0rem;
      }
    }

    .heading {
      margin-top: 3rem;
      font-family: $poppins;
      font-weight: 600;
      font-size: 22px;
      letter-spacing: 3px;

      @media (max-width: 767px) {
        margin-bottom: 1rem;
      }
    }

    p {
      width: 57%;
      margin: 0 auto;
      color: rgba($color: #ffffff, $alpha: 0.7);
      font-family: $poppins;
      font-weight: 300;
      font-size: 15px;

      @media (max-width: 767px) {
        width: 90%;
        margin-bottom: 2rem;
      }
    }

    .btn {
      background: rgba(95, 247, 255, 0.1);
      border: 1px solid rgba(112, 112, 112, 0.1);
      border-radius: 10px;
      color: #FFFFFF;
      margin-top: 3rem;
      margin-bottom: 4rem;

      @media (max-width: 767px) {
        margin-bottom: 3rem;
      }
    }

    .active {
      background: #5FF7FF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      color: #000000;
      opacity: 1;
    }

    span {
      color: rgba($color: #FFFFFF, $alpha: 0.8);
      font-size: 14px;
      letter-spacing: 1.5px;

      @media (max-width: 767px) {
        font-size: 12px;

      }
    }

   
    .hide-div {
      @media (max-width: 767px) {
      display: none;
      }
    }
    .mob-row {
      display: none;
      @media (max-width: 767px) {
        display: flex;
        }
    }
    .team-row {
      margin-bottom: 2rem;
      .col {
        @media (max-width: 767px) {
          margin-bottom: 2rem;
          }
      }
    }

    .link-img {
      display: block;
      margin: 0 auto;
      margin-top: 0.5rem;
    }

    
  }
}