.allocation {
  background: url('../../images/privacy/background.png');
  padding-bottom: 2rem;

  .box {
    margin: 0 auto;
    margin-top: 5rem;
    border-radius: 10px;
    margin-bottom: 5rem;
    background: linear-gradient(#4A289C, #00092B);

    @media(max-width: 767px) {
      width: 95%;
    }

    .wrong {
      color: red;
      text-align: center;
      font-size: 2rem;
    }

    h3 {
      margin-top: 2rem;
      text-align: center;
      color: rgba($color: #FFFFFF, $alpha: 0.8);
    }

    p,
    span {
      color: #FFFFFF;
    }

    .sub-heading {
      text-align: center;
    }

    .sub-box {
      display: flex;
      justify-content: space-between;
      background-color: rgba($color: #FFFFFF, $alpha: 0.18);
      width: 90%;
      margin: 0 auto;
      margin-bottom: 1rem;
      padding: 1rem;
      border-radius: 10px;

      @media(max-width: 767px) {
        width: 93%;
        align-items: baseline;
      }

      p {
        @media(max-width: 1200px) {
          font-size: 0.7rem;
          vertical-align: middle;

          span {
            font-size: 1rem;
          }
        }

      }

      .chain-select {
        min-width: 100px;
      }

      .form-control {
        background: none;
        font-size: 1.2rem;
        padding-left: 0;
        border: none;
        color: #FFFFFF;
      }

      .balance-div {
        width: 100%;
      }

      .title-2 {
        text-align: end;
        margin-right: 0.6rem;
      }

      .value-div {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .btn {
          background-color: #5FF7FF;
          color: #0A1A1A;
          padding: 0.15rem 0.5rem;
        }

        .from-img {
          margin-left: 8px;
        }

        .css-1fhf3k1-control,
        .css-yk16xz-control {
          border: none;
          background: none;
        }

        .css-1uccc91-singleValue {
          color: #FFFFFF;
        }

        .css-109onse-indicatorSeparator,
        .css-1okebmr-indicatorSeparator {
          display: none;
        }

        .css-tlfecz-indicatorContainer {
          color: #FFFFFF;
        }

        .css-1pahdxg-control {
          border: none;
          background: none;
          box-shadow: none;
        }

        .css-1gtu0rj-indicatorContainer {
          color: #FFFFFF;
        }

        .css-2613qy-menu {
          color: #FFFFFF;
          background: black !important;
        }
      }

      .hide {
        visibility: hidden;
      }
    }

    .swap-img {
      display: block;
      margin: 0 auto 1rem;
    }
    .swap-btn {
      background: #5FF7FF 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 20px #00000029;
      border-radius: 5px;
      color: #0A1A1A;
      font-family: $poppins;
      font-weight: 600;
      font-size: 1.2rem;
      width: 80%;
      display: block;
      margin: 0 auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }
  }
}