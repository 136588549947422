.dashboard {
  background: #000000 0% 0% no-repeat padding-box;
  height: 100vh;

  @media (max-width: 1600px) {
    height: 100%;
  }

  .col-lg-3 {
    @media (min-width: 1200px) {
      max-width: 24%;
    }
    @media (min-width: 1700px) {
      max-width: 20%;
    }
  }
  .col-lg-9 {

    @media (min-width: 1700px) {
      max-width: 75%;
    }
  }

  .side-card {
    background: linear-gradient(#4A289C, #4A289C);
    border-radius: 10px;
    color: #FFFFFF;
    padding-bottom: 2rem;
    margin-left: 0;
    padding: 2rem .5rem;
    border: none;
    &:hover {
      border: none;
    }
    @media (max-width: 1240px) {
      min-width: auto;
    }
    @media (max-width: 767px) {
      width: 95%;
      margin: 0 auto;
      margin-top: 4rem;
      margin-bottom: 1rem;
    }

    .top-img {
      text-align: end;
      width: 95%;
      margin-top: 1rem;
    }

    .profile {
      display: flex;
      align-items: center;
      width: 87%;
      margin: 0 auto;
      margin-bottom: 2rem;

      p{
        margin-bottom: 0;
      }
      div {
        margin-left: 1.3rem;
      }
    }

    h5 {
      text-align: center;
    }

    span {
      @media (max-width: 1100px) {
       font-size: 0.7rem;
      }
    }
    small {
      text-align: center;
      color: #5FF7FF;
      margin-bottom: 1rem;
    }

    p {
      color: #5FF7FF;
      font-family: $poppins;
      font-weight: 600;
    }

    .total-holding {
      display: flex;
      justify-content: space-evenly;
      text-align: center;
      font-size: 14px;
      @media (max-width: 1240px) {
        font-size: 0.7rem;
      }
      .total {
        font-size: 1.2rem;
        @media (max-width: 1240px) {
          font-size: 0.7rem;
        }
      }

      img {
        margin-bottom: 4px;
        margin-left: 3px;
      }
    }

    hr {
      border-top: 1px solid rgba($color: #FFFFFF, $alpha: 0.4);
      width: 85%;
      margin: 0 auto;
      margin-bottom: 1rem;
    }

    .card-text {
      color: #5FF7FF;
      padding: 1rem 0 0 1.5rem;
    }

    ul {
      list-style: none;
      margin-bottom: 0;
      padding-left: 1rem;

      a {
        color: #FFFFFF;
        text-decoration: none !important;
      }

      li {
        padding: 0.8rem;
        padding-left: 1rem;
        cursor: pointer;
        
        &:hover {
          border-radius: 10px;
          width: 90%;
          background: rgba(255, 255, 255, 0.18);
        }
        @media(max-width: 1290px) {
          font-size: 14px;
        }

        img {
          margin-right: 0.5rem;
        }
      }

      .disabled {
        color: gray;
      }

      .active {
        border-radius: 10px;
        width: 90%;
        background: rgba(255, 255, 255, 0.18);
        @media (max-width: 1400px) {
          width: 95%;
        }
      }
    }
  }

  .dash-heading {
    margin-top: 1.7rem;
    visibility: hidden;
  }

  .customer-div {
    background: url('../../images/dashboard/background.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    color: #FFFFFF;
    padding: 1rem;
    display: flex;
    font-family: $poppins;
    font-weight: 500;
    margin-left: 1.5rem;
    border-radius: 10px;
    height: 100%;

    @media(max-width: 767px) {
      display: block;
      height: auto;
      margin-left: 0;
      background-size: auto;
    }

    .kyc-div {
      width: 60%;
      border-radius: 4rem;
      font-family: $poppins;
      margin-bottom: 17rem;
      margin-top: 2rem;
      font-weight: 500;

      @media (max-width: 1400px) {
        width: 70%;
      }
      @media(max-width: 767px) {
        width: 100%;
        margin-bottom: 2rem;
      }

      p {
        width: 90%;
        margin: 0 auto;
        font-family: $poppins;
        font-weight: 600;
      }

      .paragraph {
        font-size: 14px;
        font-weight: 400;
        width: 90%;
        margin-top: 0.7rem;
      }

      .btn {
        background: #4388FF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        color: #FFFFFF;
        padding: 0.7rem;
        font-family: $poppins;
        margin-top: 2rem;
        margin-left: 2rem;
        font-weight: 500;
      }

      .synaps {
        background-color: #0E2437;
        padding-left: 1.8rem;
        padding-right: 2.8rem;
        margin-top: 0;
      }
    }

    .hr-div {
      display: flex;
      width: 45%;
      margin-bottom: 2rem;
      margin-top: 2rem;
      margin-left: 2rem;

      @media (max-width: 767px) {
        width: 80%;
      }

      span {
        color: rgba($color: #FFFFFF, $alpha: 0.7);
      }

      hr {
        border-top: 1px solid rgba($color: #FFFFFF, $alpha: 0.4);
        border-color: rgba($color: #FFFFFF, $alpha: 0.4);
        width: 39%;
        margin-top: 0.7rem;
        margin-bottom: 0;
      }
    }

    .print-img {
      display: block;
      margin: 0 auto;
      margin-bottom: 5rem;
    }
  }

  .project {
    background: linear-gradient(#4A289C, #00092B);
    color: #FFFFFF;
    font-family: $poppins;
    font-weight: 500;
    margin-left: 1.5rem;
    border-radius: 10px;
    text-align: center;
    padding-bottom: 1rem;
    height: 100%;

    @media (max-width: 767px) {
      margin-left: 0;
      width: 95%;
      margin: 0 auto;
    }
    .top-button {
      margin-top: 2rem;
      margin-bottom: 2rem;
      margin-right: 1rem;
      border: 1px solid rgba($color: #5FF7FF, $alpha: 0.5);
      @media (max-width: 767px) {
        margin-bottom: 0;
      }
    }
    .active {
      background-color: #5FF7FF;
      color: #112626;
    }

    .scroll {
      width: 99% !important;
      margin: 0 auto;
    }

    @media (min-width: 2200px) {
      .col-xl-4{
        width: 20%;
        max-width: 20%;
        flex: 0 0 20%;

      }
    }
    .card {
      background: linear-gradient(#4A289C, #4A289C);
      color: #FFFFFF;
      text-align: initial;
      border-radius: 10px;
      margin: 0 .5rem;
      margin-top: 1rem;
      max-width: 350px;

     

      .card-body {
        @media(max-width:1250px) {
          padding: 0.8rem;
        }
      }

      .card-title {
        margin-top: 0.5rem;
      }

      h4 {
        color: #5FF7FF;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        font-size: 1rem;
      }

      small {
        color: rgba($color: #FFFFFF, $alpha: 0.8);
        font-size: 0.8rem;
      }

      .progress {
        height: 0.5rem;
        background: rgba($color: #F3F3F3, $alpha: 0.05) 0% 0% no-repeat padding-box;
      }

      .progress-bar {
        background: #5FF7FF 0% 0% no-repeat padding-box;
        border-radius: 10px;
        height: 0.5rem;
      }

      .mid-div {
        display: flex;
        justify-content: space-between;

        @media(max-width: 1200px) {
          font-size: 0.6rem;
        }
      }

      .bottam-div {
        display: flex;
        justify-content: space-between;
        @media(max-width:1180px) {
          font-size: 0.7rem;
          small {
            font-size: 0.6rem;
          }
        }
        p {
          color: #5FF7FF;
        }
      }
    }

    .sec-card {
      border: none;
      background: rgba($color: #F3F3F3, $alpha: 0.05) 0% 0% no-repeat padding-box;

    }

    .hide {
      visibility: hidden;
    }
  }

  .search-input {
    background: rgba($color: #FFFFFF, $alpha: 0.18);
    border-radius: 0.4rem;
    padding: 1.5rem 3rem 1.5rem 3rem;
    border: none;
    color: rgba($color: #FFFFFF, $alpha: 0.7);

  }

  .large-loader {
    text-align: center;
    position: relative;
    top: 12rem;
    margin-top: -6.2rem;
  }
  .search-icon {
    position: relative;
    top: -2.3rem;
    left: 1%;
    width: 1.3rem;
  }

  ::placeholder {
    color: rgba($color: #FFFFFF, $alpha: 0.7);
    margin-left: 2rem;
  }

  .filters-div {
    display: flex;
    justify-content: space-between;
    
    @media (max-width: 767px) {
      display: initial;
    }
    .css-2b097c-container, .css-yk16xz-control {
      background: none;
      border: none;
      width: 10rem;
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-1uccc91-singleValue {
      color: #FFFFFF;
    }
    .css-1gtu0rj-indicatorContainer {
      color: #FFFFFF;
    }
    .css-1pahdxg-control {
      width: 10rem;
      border: none;
      background: none;
      box-shadow: none;
    }
    .check-div {
      color: #FFFFFF;
      display: flex;
      justify-content: space-between;
      width: 30%;

    @media(max-width: 1300px) {
      width: 40%;
    }
    @media (max-width: 767px) {
      width: auto;
    }
    .rc-checkbox-inner {
      background: none;
      width: 1rem;
      height: 1rem;

      &:after {
        display: none;
      }
    }
    .rc-checkbox-checked .rc-checkbox-inner {
      background-color: #5FF7FF !important;
    }
  }
  }

  .accumulation-div {
    background: linear-gradient(#4A289C, #00092B);
    padding: 1rem 1rem 1px 1rem;
    font-family: $poppins;
    font-weight: 500;
    margin-left: 1.5rem;
    border-radius: 10px;
    height: 100%;

    @media (max-width: 767px) {
      display: none;
    }

    .top-table {
      margin-bottom: -1rem;
    }
    table {
      border-collapse: separate;
      border-spacing: 0 10px;

      .hide {
        visibility: collapse;

      }

      .top-div {
        border-radius: 6px;
        color: rgba($color: #FFFFFF, $alpha: 0.8);
        background: rgba($color: #FFFFFF, $alpha: 0.1);
        
        th:first-child {
          border-bottom-left-radius: 6px;
          border-top-left-radius: 6px;
        }

        th:last-child {
          border-bottom-right-radius: 6px;
          border-top-right-radius: 6px;
          text-align: center;
        }

        th {
          border: none !important;
          vertical-align: inherit;
        }
      }

      .color-td {
        color: #5FF7FF;
      }

      td {
        border: none;
        padding: 11px 5px 9px 13px;
        vertical-align: inherit;
      }

      .sec-div {
        cursor: pointer;
        color: #FFFFFF;
        &:hover {
          background: #4f3892;
        }
        td {
          border-top: 1px solid rgba(243, 243, 243, 0.3);
          border-bottom: 1px solid rgba(243, 243, 243, 0.3);
          opacity: 1;
        }

        td:first-child {
          border-left: 1px solid rgba(243, 243, 243, 0.3);
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
        }

        td:last-child {
          border-right: 1px solid rgba(243, 243, 243, 0.3);
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
        }

        .btn {
          border-radius: 5px;
          font-size: 12px;
          font-family: $poppins;
          font-weight: 600;
          width: 70%;


          @media (max-width: 1600px) {
            width: 87%;
          }

        }

        .claim {
          background-color: #5FF7FF;
        }
      }
    }

  }

  .mobile-accumulation{
    display: none;
    background: linear-gradient(#4A289C, #00092B);
    padding: 0.5rem;
    width: 95%;
    margin: 0 auto;
    border-radius: 10px;
    @media (max-width: 767px) {
      display: block;
    }

    .filters-div {
      width: 100%;
      color: #FFFFFF;
    } 
    .search-icon {
      width: 5%;
      top: -2.3rem;
      left: 2%;
    }
  .scroll-m {

    .accumulation-mobile {
      display: none;

      @media (max-width: 767px) {
        display: initial;
      }

      .card {
        background: linear-gradient(#4A289C, #00092B);
        color: #FFFFFF;
        text-align: center;
        color: #FFFFFF;
        border-radius: 20px;
        width: 95%;
        margin: 0 auto;
        margin-bottom: 1rem;

        h5 {
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
          font-size: 15px;
          font-family: $poppins;
          font-weight: 500;
        }

        .box {
          display: flex;
          justify-content: space-evenly;
          margin-bottom: 1rem;

          span {
            color: #5FF7FF;
            font-size: 16px;
          }

          .time {
            color: #50EB4A;
          }

          .exp {
            color: red;
          }

          p {
            font-size: 12px;
            font-family: $poppins;
            font-weight: 300;
          }
        }

        .btn {
          color: #FFFFFF;
          border-radius: 20px;
          font-size: 12px;
          background-color: rgba(0, 0, 0, 0.3);
          width: 35%;
          margin: 0 auto;
          margin-bottom: 1rem;
        }
        .badge {
          background-color: #28a745;
        }

        .claim {
          background-color: #5FF7FF;
          color: #000000;
        }
      }
    }
  }
}
  .calculator {
    background: linear-gradient(#4A289C, #00092B);
    color: #FFFFFF;
    margin-left: 1.5rem;
    border-radius: 10px;
    padding: 1.5rem;
    height: 100%;

    @media (max-width: 767px) {
      width: 95%;
      margin: 0 auto;
      height: auto;
    }

    .left {
      background: linear-gradient(rgba($color: #8F67F0, $alpha: 0.5),rgba($color: #4A289C, $alpha: 0.5) );
      border-radius: 10px;
      text-align: center;
      max-width: 49%;
      margin: 0 auto;
      padding: 7px 15px 7px 15px;

      h5 {
        color: #5FF7FF;
        margin-top: 1rem;
        font-size: 2rem;
        margin-bottom: 2rem;
        font-family: $poppins;
        font-weight: 600;
      }

      @media (max-width: 1200px) {
        max-width: initial;
        h5 {
          font-size: 1.2rem;
        }
        
      }
      .message {
        background-color: rgba($color: #5FF7FF, $alpha: 0.2);
        display: flex;
        justify-content: space-evenly;
        padding: 1.5rem;
        margin: 0 auto;
        border-radius: 16px;
        text-align: justify;
        margin-top: 3rem;
        width: 90%;

        div {
          width: 80%;

          .p1 {
            font-family: $poppins;
            font-weight: 600;
            margin-bottom: 0;

            span {
              color: #07CF1C;
            }
          }

          .p2 {
            font-family: $poppins;
            font-weight: 400;
            font-size: 14px;

            span {
              color: #5FF7FF;
            }
          }
        }
      }

      .msg {
        background: none;

      }
       
      p{
        font-family: $poppins;
        font-weight: 500;
      }
      .result {
        background-color: rgba($color: #07CF1C, $alpha: 0.3);
        padding: 1rem;
        border-radius: 10px;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 1rem;

        p {
          width: 70%;
          margin: 0 auto;
          margin-bottom: 1rem;
        }

        h4 {
          color: #5FF7FF;
        }

      }

      .error-result {
        background: rgba($color: #F90303, $alpha: 0.4);
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      ::placeholder {
        font-size: 13px;

        @media (max-width: 1560px) {
          font-size: 12px;
        }
      }

      .input-div {
        justify-content: center;

        input {
          padding: 2rem;
          width: 90%;
          margin: 0 auto;
          margin-bottom: 1rem;
          text-align: center;
          color: #FFFFFF;
          border: none;
          background: rgba($color: #FFFFFF, $alpha: 0.18) 0% 0% no-repeat padding-box;
        }

        ::placeholder {
          color: #FFFFFF;
        }
      }

      .error {
        color: red;
        font-size: 14px;
        margin-top: 0rem;
        display: none;
      }

      .btn {
        margin-top: 3rem;
        margin-bottom: 1rem;
        font-family: $poppins;
        font-weight: 600;
      }
    }

    .right {
      background: linear-gradient(rgba($color: #8F67F0, $alpha: 0.5),rgba($color: #4A289C, $alpha: 0.5) );
      border-radius: 10px;
      margin-left: 0.5rem;
      text-align: justify;
      padding: 3.2rem 2.1rem 7px 2.1rem;
      max-width: 49%;
      font-family: $poppins;
      font-weight: 400;
      margin: 0 auto;
      @media (max-width: 1200px) {
        max-width: 48%;
        font-size: 0.8rem !important;
      }
      @media (max-width: 767px) {
        margin-left: 0px;
        margin-top: 0.8rem;
        max-width: 100%;
      }

      ul {
        list-style: none;
      }
      .link {
        cursor: pointer;
        color: #5FF7FF !important;
        text-decoration: underline;
      }
      h4 {
        font-family: $poppins;
        font-weight: 500;
      }
      ul li::before {
        content: "\2022";
        color: #5FF7FF;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }

      span {
        color: #5FF7FF;
      }

      h4 {
        color: #5FF7FF;
        margin-top: 1rem;
      }
    }
  }

  .lock {
    background: linear-gradient(#4A289C, #00092B);
    color: #FFFFFF;
    margin-left: 1.5rem;
    border-radius: 10px;
    padding: 11px;

    @media (max-width: 767px) {
      width: 95%;
      margin: 0 auto;
    }

    .left {

      .header-div {
        background: linear-gradient(rgba($color: #8F67F0, $alpha: 0.5),rgba($color: #4A289C, $alpha: 0.5) );
        border-radius: 10px;
        text-align: center;
        padding: 15px 7px 7px 15px;
        display: flex;
        margin-top: 1rem;
        margin-left: 10px;
        justify-content: space-around;

        h1 {
          font-size: 3rem;
          font-family: $poppins;
          font-weight: 600;
          color: #5FF7FF;
        }
      }

      .body-div {
        margin-top: 1rem;
        background: linear-gradient(rgba($color: #8F67F0, $alpha: 0.5),rgba($color: #4A289C, $alpha: 0.5) );
        margin-left: 10px;
        border-radius: 10px;
        padding: 1rem;
        text-align: center;
        padding-bottom: 1rem;
      }

      p {
        font-size: 14px;
        color: rgba($color: #FFFFFF, $alpha: 0.7);
        width: 80%;
        margin: 0 auto;
        margin-bottom: 1rem;
        @media (max-width: 1200px) {
          width: 100%;
        }
      }

      ::placeholder {
        color: rgba($color: #FFFFFF, $alpha: 0.8);
      }

      input {
        margin: 0 auto;
        width: 90%;
        padding: 1.5rem;
        margin-bottom: 1rem;
        text-align: center;
        color: #FFFFFF;
        border-radius: 10px;
        border: none;
        background: rgba($color: #FFFFFF, $alpha: 0.18);

        @media (max-width: 767px) {
          width: 90%;
        }
      }

      h6 {
        margin-bottom: 1rem;
      }

      .btn {
        border-radius: 10px;
        margin-top: 1rem;
        margin-bottom: 2rem;

        @media (max-width: 1200px) {
          width: 70%;
          margin-top: 0;
          margin-bottom: 1rem;
        }
      }
    }

    .right {
      background: linear-gradient(rgba($color: #8F67F0, $alpha: 0.5),rgba($color: #4A289C, $alpha: 0.5) );
      border-radius: 10px;
      padding: 15px 15px 0px 15px;
      margin-left: 1rem;
      max-width: 56%;
      margin-top: 1rem;
      margin-bottom: 1rem;

      @media (max-width: 767px) {
        margin-left: 0;
        margin-top: 1rem;
        max-width: 100%;
      }
      .top-table {
        margin-bottom: -1rem;
      }
      .scroll {
        @media (max-width: 767px) {
          display: none;
        }
      }

      table {
        border-collapse: separate;
        border-spacing: 0 10px;

        @media (max-width: 767px) {
          display: none;
        }

        .hide {
          visibility: collapse;

        }

        .top-div {
          background-color: rgba(243, 243, 243, 0.1);
          border-radius: 20px;

          @media (max-width: 1550px) {
            font-size: 12px;
          }

          th:first-child {
            border-bottom-left-radius: 16px;
            border-top-left-radius: 16px;
          }

          th:last-child {
            border-bottom-right-radius: 16px;
            border-top-right-radius: 16px;
            text-align: center;
          }

          th {
            border: none !important;
          }
        }

        td {
          border: none;
          padding: 11px 5px 9px 13px;
          vertical-align: middle;
        }

        .sec-div {
          cursor: pointer;
          background-color: rgba($color: #5FF7FF, $alpha: 0.05);
          &:hover {
            background: #7656c3;
          }
          @media(max-width: 1240px) {
            font-size: 0.7rem
          }

          td {
            border-top: 1px solid rgba(243, 243, 243, 0.3);
            border-bottom: 1px solid rgba(243, 243, 243, 0.3);
            opacity: 1;
          }

          .color-td {
            color: #5FF7FF;
          }

          td:first-child {
            border-left: 1px solid rgba(243, 243, 243, 0.3);
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
          }

          td:last-child {
            border-right: 1px solid rgba(243, 243, 243, 0.3);
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
          }

          .btn {
            min-width: 76px;
            padding: 0.3rem 1rem 0.3rem 1rem;
          }

          .claim {
            background-color: #5FF7FF;
          }
        }
      }

      .scroll-m {

        display: none;

        div:first-child {
          margin-right: 0 !important;
        }

        @media (max-width: 767px) {
          display: block;
        }

        .card {
          background: rgba($color: #5FF7FF, $alpha: 0.1) 0% 0% no-repeat padding-box;
          border-radius: 20px;

          .box {
            display: flex;
            justify-content: space-between;
            padding: 10px;

            span {
              color: rgba($color: #FFFFFF, $alpha: 0.5);
            }

            .count {
              span {
                color: #5FF7FF;
              }
            }

            p {
              color: #5FF7FF;
              text-align: center;
            }
          }

          .sec-box {
            display: flex;
            justify-content: space-between;
            width: 91%;
            margin-left: 8px;
            align-items: center;

            .btn {
              background: #5FF7FF 0% 0% no-repeat padding-box;
              box-shadow: 0px 3px 6px #00000029;
              border-radius: 21px;
              color: #112626;
              width: 34%;
              height: 2.5rem;
            }

            span {
              color: rgba($color: #FFFFFF, $alpha: 0.5);
            }

            p {
              color: #5FF7FF;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .apply {
    color: #FFFFFF;
    margin-left: 1.5rem;
    border-radius: 1rem;
    height: 100%;
    margin-bottom: 27rem;

    @media (max-width: 767px) {
      width: 95%;
      margin: 0 auto;
    }
    .right {
      background: linear-gradient(#4A289C, #00092B);
      border-radius: 10px;
      text-align: center;
      max-width: 50%;
      margin: 0 0 0 auto;
      padding-top: 1rem;

      @media (max-width: 767px) {
        max-width: 100%;
      }
      .scroll {
        height: 85% !important;
        div:first-child {
          padding: 1.5rem;
          padding-top: 0.3rem;
        }
        @media (max-width: 767px) {
          height: 30rem !important;
        }

        .track-vertical {
          width: 2px !important;
          right: 2.5% !important;
          background: linear-gradient(#4A289C, #00092B);
          height: 34rem;

          @media (max-width: 767px) {
            right: -1.5%;
          }

          div {
            background-color: #5FF7FF !important;
            height: 30rem;
            padding: 0;
          }
        }
        .track-horizontal {
          div {
            padding: 0;
            background: none;
          }
        }

        h4 {
          margin-top: 2rem;
        }

        p {
          font-size: 1rem;
          color: rgba($color: #FFFFFF, $alpha: 0.7);
          width: 97%;

          @media (max-width: 767px) {
            width: 92%;
          }
        }

        hr {
          border: 1px solid rgba($color: #FFFFFF, $alpha: 0.5);
          width: 70%;
        }

        ::placeholder {
          color: #FFFFFF;
        }

        input {
          width: 95%;
          padding: 1.2rem;
          margin-bottom: 1rem;
          text-align: center;
          color: #FFFFFF;
          margin-top: 1rem;
          border-radius: 10px;
          background: none;

          @media (max-width: 767px) {
            width: 92%;
          }
        }

        .pool-div {
          display: flex;
          width: 97%;
          justify-content: space-around;

          @media (max-width: 767px) {
            width: 92%;
          }

          .active {
            background: #5FF7FF !important;
            color: #112626;
          }
        }

        p {
          background-color: rgba($color: gray, $alpha: 0.1);
          color: rgba($color: #FFFFFF, $alpha: 0.7);
          border-radius: 10px;
          padding: 0.5rem;
          overflow: hidden;
          text-overflow: ellipsis;

        }

        h6 {
          @media (max-width: 767px) {
            width: 92%;
          }
        }

        label {
          text-align: initial;
          display: block;
          width: 95%;
          margin: 0 auto;
          display: flex;
          align-items: baseline;
          margin-bottom: 0.5rem;

          div {
            margin-left: 0.5rem;
          }
          @media (max-width: 767px) {
            width: 91%;
          }

          .rc-checkbox-input:focus+.rc-checkbox-inner {
            border: 1px solid #5FF7FF;
          }

          .rc-checkbox-inner {
            border-radius: none;
            background-color: #4A289C;

            &:after {
              border: none;
            }
          }

          .rc-checkbox-checked {
            .rc-checkbox-inner {
              background-color: #5FF7FF;
              border: 1px solid #5FF7FF;
            }
          }
        }

        .link {
          color: #5FF7FF !important;
        }
      }

      a {
        color: #5FF7FF;
      }

      .end {
        color: #5FF7FF;
        margin-top: 10rem;
        font-size: 22px;
      }

      .success-div {
        h3 {
          font-size: 1.3rem;
          margin-top: 1rem;
        }

        .edit {
          display: block;
          margin: 0 auto;
          margin-top: 2rem;
          border-radius: 10px;
          width: 69%;
          border: 1px solid #fff;
          color: #FFFFFF;

          &:hover{
            background: #5FF7FF !important;
            color: #000;
          } 
        }

        img {
          margin-top: 4rem;
        }


        p {
          margin-bottom: 0;
        }
      }

      .btn {
        border-radius: 10px;
        opacity: 1;
        margin-bottom: 1rem;
        margin-top: 0.5rem;

        @media (max-width: 1110px) {
          font-size: 0.8rem;
        }
      }
      .error {
        color: #FFD608;
      }
    }

    .left {
      background: linear-gradient(#4A289C, #00092B);
      border-radius: 10px;
      max-width: 49%;

      @media (max-width: 767px) {
        max-width: 100%;
        margin-bottom: 1rem;
      }

      .top {
        display: flex;
        align-items: center;
        padding: 1rem;

        .btn {
          color: #5FF7FF;
          background: rgba($color: #5FF7FF, $alpha: 0.5);
          border-radius: 10px;
          margin-left: 5px;
          height: 2rem;
          line-height: 1px;
          padding-bottom: 8px;
        }
      }

      .scroll {
        height: 82% !important;
        div:first-child {
          padding: 1.5rem;
          padding-top: 0.3rem;
        }
        @media (max-width: 767px) {
          height: 30rem !important;
        }

        .track-vertical {
          width: 2px !important;
          right: 3.5% !important;
          background: linear-gradient(#4A289C, #00092B);
          height: 34rem;

          div {
            background-color: #5FF7FF !important;
            padding: 0;
            height: 30rem;
          }
        }
        .track-horizontal {
          div {
            padding: 0;
            background: none;
          }
        }
      }

      h5 {
        font-family: $poppins;
        font-weight: 600;
        margin-top: 1rem;
        font-size: 1.2rem;
        width: 98%;
      }

      p,
      li {
        font-size: 15px;
        width: 98%;
        color: rgba($color: #FFFFFF, $alpha: 0.8);
      }

      a {
        color: #5FF7FF;
      }

    }
  }
}