.ido {
    
    background: url('../../images/nftMarket/background.png');
    color: #ffffff;
    .sub-div{
        text-align: center;
    h1 {
        margin-top: 3rem;
    }
      
    span {
        color: #5ff7ff;
    }

    p {
        color: rgba($color: #FFFFFF, $alpha: 0.8);
        margin-top: 2rem;
    }

    .paragraph {
        @media (max-width: 767px) {
            width: 90%;
            margin: 0 auto;
        }
    }

 
    h5 {
        color: #5ff7ff;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }
    .img-div {
        display: flex;
        width: 50%;
        margin: 0 auto;
        @media(max-width: 767px) {
          width: 93%;
        }
        p {
            margin-left: 14px;
            margin-top: 0;
            text-align: initial;
            a {
                color: #5ff7ff;
            }
        }
        img {
            height: 10%;
            width: 3.5%;
            margin-top: 0.5rem;
        }
    }
    .btn {
        .ap2 {
          display: none;
        }
  
        img {
          margin-right: 6px;
        }
  
        border: 1px solid #fff;
        margin: 0 auto;
        border-radius: 5px;
        display: inline-block;
        padding: 0.6rem 3rem;
        margin: 0 auto;
        color: #5ff7ff;
        font-size: 1.1rem;
        transition: all .3s;
        margin-bottom: 1rem;
        font-family: "Poppins";
        font-weight: 500;
        position: relative;
        overflow: hidden;
        z-index: 1;
        a {
            color: #5ff7ff;
        }
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background:none;
          border-radius: 10rem;
          z-index: -2;
        }
  
        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0%;
          height: 100%;
          background-color: #5FF7FF;
          transition: all .3s;
          z-index: -1;
        }
  
        &:hover {
          color: #000000;
  
          .ap {
            display: none;
          }
  
          .ap2 {
            display: initial;
          }
  
          &:before {
            width: 100%;
          }
        }
    }
 
    .sec {
        margin-bottom: 3rem;
        padding: 0.6rem 2rem;
    }
    }
    .rocket {
        margin-top: 14rem;
        width: 80%;

        @media (max-width: 767px) {
            margin-top: 2rem;
        }
    }
}