.king {
  background-color: #000000;
  background: url('../../images/privacy/background.png');

  .line {
    width: 1px;
    height: 30rem;
    background-color: rgba($color: #FFFFFF, $alpha: 0.5);
    display: block;
    position: absolute;
    top: 22%;
    right: 1%;
  }
  h1 {
    font-family: $poppins;
    font-weight: 600;
  }

  h5 {
    font-family: $poppins;
    font-weight: 500;
    color: #FFFFFF;
  }
.web {
  @media (max-width: 767px) {
    display: none;
  }
}
.mobile  {
    display: none;
    @media (max-width: 767px) {
    display: initial;
  }
}
  .left {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    margin-top: 3rem;

   
    img {
      margin-bottom: 2rem;
      @media (max-width: 767px) {
        margin-bottom: 0;
        margin-top: 2rem;
      }
    }
    h1 {
      text-align: center;
      color: #FFFFFF;

    }

    p {
      width: 69%;
      margin: 0 auto;
      text-align: center;
      color: rgba(255, 255, 255, 0.7);
      font-family: $poppins;
      font-weight: 400;
      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .sub-div {
      display: flex;
      // margin-top: 3rem;
      @media (max-width: 767px) {
      display: block; 
      }

      div {
        text-align: center;
        margin-top: 2rem;

        h5 {
          color: #5FF7FF;
          // margin-top: 0.5rem;

        }

        .heading {
          margin-top: 0.2rem;
        }
      }
    }

    .third-div {
      width: 70%;
      margin: 0 auto;
      margin-bottom: 5rem;
      margin-top: 2rem;
      @media (max-width: 767px) {
        width: 100%;
        margin-top: 2rem;
      }
      h5 {
        color: #5FF7FF;
      }
    }
  }

  .right {
    text-align: center;
    margin-top: 6rem;
    @media (max-width: 767px) {
     margin-top: 2rem;
     margin-bottom: 3rem;
    }

  //   .count {
  //     span {
  //         color: #5FF7FF;
  //         font-size: 3rem;
  //         letter-spacing: 8px;
  //     }
  // }
    h1 {
      color: #5FF7FF;
      font-size: 80px;
      font-family: $poppins;
      font-weight: 600;
      @media (max-width: 767px) {
        font-size: 3rem;
       }
    }

    p {
      color: #FFFFFF;
      font-size: 22px;
      font-family: $poppins;
      font-weight: 500;
    }

    span {
      color: rgba($color: #FFFFFF, $alpha: 0.9);
      width: 50%;
      margin: 0 auto;
      display: block;
      @media (max-width: 767px) {
        width: 90%;
       }

    }

    .btn {
      margin-top: 2rem;
    }

    // .time {
    //   display: flex;
    //   justify-content: space-around;
    //   width: 44%;
    //   margin: 0 auto;
    //   @media (max-width: 1500px) {
    //       width:  64%;
    //   }

    //   @media (max-width: 767px) {
    //     width: 79%;
    //     justify-content: space-between;
    //     }

    //   span {
    //     color: rgba(255, 255, 255, 0.5);
    //     font-size: 12px;
    //     text-align: center;
    //     display: block;
    //     font-family: $poppins;
    //       font-weight: 300;
    //   }

    //   .line {
    //     display: block;
    //     width: 2px;
    //     height: 1.5rem;
    //     background-color: #FFFFFF;
    //     margin-top: 2rem;
    //   }
    // }

  }
}