
.pool {
    .top-div{
        background: #000000 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        color: #FFFFFF;
        text-align: center;
        p {
            width: 60%;
            margin: 0 auto;
        }
        small {
            color: #5FF7FF;
        }
        .btn {
            border: 1px solid #FFFFFF;
            color: #5FF7FF;
            border-radius: 28px;
            margin-bottom: 2rem;
            margin-left: 1rem;
            margin-top: 2rem;
            width: 10%;
            background: #0C1415 0% 0% no-repeat padding-box;
            @media (max-width: 767px) {
                width: auto;
               }
            &:hover {
              background-color: #5FF7FF;
              color: #000000;
            }
      
          }
    }

    .carousal-div {
        background: #000000 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        .aoTUt {
            right: 25rem;
            @media (max-width: 767px) {
                right: 1rem;
               }
        }
        .dHQcjo {
            left: 28rem;
            @media (max-width: 767px) {
                left: 1rem;
               }
        }
        .btn {
            cursor: pointer;
        border: 1px solid #FFFFFF;
        color: #FFFFFF;
        border-radius: 39px;
        }
        .euKzJn {
            color: #FFFFFF;
            font-weight: bold;
        }
        .card{
            background: #000000 0% 0% no-repeat padding-box;
            color: #FFFFFF;
            border: 1px solid #FFFFFF;
            margin-left: 1rem;

            ul {
                display: flex;
                list-style: none;
                justify-content: space-between;
            }
            hr {
                border-top: 1px solid rgba(0,0,0,.1) !important;
                border: 1px solid #FFFFFF;
                margin-top: 0.6rem;
                margin-bottom: 0.6rem;
            }
            .progress-bar {
                background: #5FF7FF 0% 0% no-repeat padding-box;
                border-radius: 10px;
              }
            .price, .mid-div,{
                display: flex;
                justify-content: space-between;
                small {
                    color: #5FF7FF;
                }
            }
             .botam-div{
                display: flex;
                justify-content: space-between;
                p {
                    color: #5FF7FF;
                }
            }
        }
        .text-card {
            text-align: center;
        }
    }
}