.white {
  background: #11062B 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  color: #FFFFFF;

  .col {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .loader {
    text-align: center;
  }
  .scroll {
    @media(max-width: 767px) {
      height: 300px !important;
      width: 98% !important;
    }

    .track-vertical {
      position: absolute;
      width: 6px;
      right: -0.5%;
      height: 34rem;

      div {
        background-color: #5FF7FF !important;
        height: 5rem !important;
      }
    }
  }

  .left {
    padding-right: 0.8rem;
    background: linear-gradient(#4A289C, #11062B);
    margin-top: 3rem;
    border-radius: 0.5rem;
    padding: 0.3rem;


    @media (max-width: 770px) {
      padding-left: 0.8rem;
      margin-left: 0 !important;
    }
  }

  .right {
    padding-left: 0.8rem;
    background: linear-gradient(#4A289C, #11062B);
    margin-top: 3rem;
    margin-bottom: 5rem;
    border-radius: 0.5rem;
    padding: 0.3rem;

    @media (max-width: 767px) {
      padding-right: 0.8rem;
      margin-right: 0 !important;
    }

    //  p {
    //    @media (min-width: 1600px)
    //    {
    //      font-size: 16px;
    //    }
    //  }
  }

  .top-div {
    margin-top: 1rem;
    text-align: center;

    .live {
      line-height: 1.2;      
      font-size: 1rem;
      margin-left: 0.8rem;
      margin-bottom: 0.5rem;
      color: #112626;
      @media not all and (min-resolution:.001dpcm) {
        @media (max-width: 1500px) {
          .safari10 {
            height: 100%;
            margin-top: -7px;
          }
        }
      }

    }

    h3 {
      color: #5FF7FF;
      font-size: 1.6rem;

      img {
        margin-bottom: 0.3rem;
      }
    }

    .btn {
      background: #5FF7FF 0% 0% no-repeat padding-box;
      border-radius: 10px;
      color: #070707;
      height: 2rem;
      padding-top: 4px;
      box-shadow: 1px 0px 12px 11px rgba($color: #5FF7FF, $alpha: 0.15);
    }

    p {
      margin-top: 0.3rem;
    }
  }

  input {
    border: none;
    background: rgba($color: #FFFFFF, $alpha: 0.18);
    border-radius: 10px;
    margin: 0 auto;
    width: 93%;
    padding-top: 21px;
    color: #FFFFFF;
    padding-bottom: 22px;

    &:focus {
      background: rgba($color: #FFFFFF, $alpha: 0.18);
      color: #FFFFFF;
    }
  }

  .search {
    position: relative;
    top: -35px;
    float: right;
    width: 6%;
    right: 21px;
  }

  .sec-div {
    border: 1px solid var(--unnamed-color-5ff7ff);
    border: 1px solid rgba($color: #5FF7FF, $alpha: 0.97);
    border-radius: 10px;
    margin-top: 1rem;
    padding: .3rem;
    
    .sub-div {
      display: flex;
      justify-content: space-evenly;
      margin: .8rem auto;

      div {
        border: 1px solid var(--unnamed-color-5ff7ff);
        border: 1px solid #5FF7FF;
        border-radius: 5px;
        color: #5FF7FF;
        padding: 4px 6px;
        font-size: 0.8em;
        width: 15%;
        text-align: center;
      }

      .hide {
        visibility: hidden;
      }

      p {
        font-size: 0.8em;
        background: rgba($color: #5FF7FF, $alpha: 0.1) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        padding: 0.3rem;
        width: 70%;
        word-break: break-all;
        margin-bottom: 0;
      }

      @media (max-width: 2560px) {
        img {
          margin: auto !important;
        }
        div {
          margin: auto !important;
        }
        p {
          margin: auto !important;
        }
      }

    }
  }

  .top-box {
    border: 1px solid var(--unnamed-color-5ff7ff);
    background: rgba($color: #4A289C, $alpha: 0.2);
    border-radius: 10px;
    margin-top: 3.5rem;

    .header {
      display: flex;
      justify-content: space-between;

      p {
        margin-left: 1rem;
        margin-top: 0.3rem;
        margin-bottom: 0.4rem;
      }
    }

    .arrow {
      margin-right: 1rem;
      cursor: pointer;
    }

    .horse-div {
      text-align: initial;
      display: flex;
      width: 90%;
      justify-content: space-evenly;
      margin: 0 auto;

      @media (max-width: 767px) {
        width: 100%;
      }

      .horse-img {
        @media (max-width: 767px) {
          margin-bottom: 1.4rem;
        }
      }

      div {
        width: 77%;

        p {
          font-size: 13px;
        }
      }

      .view {
        color: rgba($color: #5FF7FF, $alpha: 1);
        float: right;
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
      }

    }

    .alloc-div {
      display: flex;
      justify-content: space-between;
      width: 88%;
      margin: 0 auto;
      margin-bottom: 2rem;

      p {
        color: #5FF7FF;
        font-size: 14px;
        margin-bottom: 0;
        text-align: center;
      }

      span {
        text-align: center;
        display: block;
        color: rgba($color: #FFFFFF, $alpha: 0.8);
      }
    }

    .time-div {
      width: 69%;
      display: flex;
      text-align: center;
      justify-content: space-evenly;
      margin: 0 auto;
      margin-bottom: 2rem;

      p {
        color: #5FF7FF;
        font-size: 14px;
        margin-bottom: 0;
        text-align: center;
      }

      span {
        text-align: center;
        display: block;
        color: rgba($color: #FFFFFF, $alpha: 0.8);
      }
    }

  }

  .mid-div {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .sub-mid {
      width: 50%;
      background: rgba($color: #4A289C, $alpha: 0.2) 0% 0% no-repeat padding-box;
      border: 1px solid rgba($color: #5FF7FF, $alpha: 0.1);
      border-radius: 10px;
      padding: 1.2rem;
      margin-left: 0.5rem;
      text-align: center;

      .progress {
        height: 0.5rem;
        margin-top: 0.5rem;
        background: rgba($color: #5FF7FF, $alpha: 0.1) 0% 0% no-repeat padding-box;

        .progress-bar {
          background-color: rgba($color: #5FF7FF, $alpha: 1);
        }
      }

      h2 {
        margin-top: 0.7rem;

        span {
          background: linear-gradient(#4A289C, #00092B);
          margin-left: 5px;
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      p {
        font-size: 14px;
        margin-bottom: 5px;
        color: #5FF7FF;
      }
    }
    .first-mid {
      margin-left: 0;
      margin-right: 0.5rem;
    }
  }

  .winnerlist-div {
    background: linear-gradient(#4A289C, #11062B);
    padding: 1rem;
    border-radius: 10px;
  }
  ::placeholder {
    color: rgba($color: #FFFFFF, $alpha: 0.7);
  }
  .search-input {
    border: 1px solid rgba($color: #D6D6D6, $alpha: 0.4);
    width: 100%;

    @media (max-width: 770px) {
      width: 95%;
    }
  }

  .cup-div {
    display: flex;
    justify-content: space-between;

    @media (max-width: 770px) {
      width: 92%;
      margin: 0 auto;
    }

    h5 {
      color: rgba($color: #5FF7FF, $alpha: 1);
    }

    span {
      color: #0AFF00;
    }
  }

  .bottom-div {
    border: 1px solid rgba($color: #D6D6D6, $alpha: 0.4);
    border-radius: 10px;
    margin-top: 1rem;

    .bottom-head {
      display: flex;
      width: 97%;
      margin: 0 auto;
      padding: 6px;
      justify-content: space-between;
      border: 1px solid rgba($color: #5FF7FF, $alpha: 0.9);
      background-color: rgba($color: #5FF7FF, $alpha: 0.1);
      border-radius: 10px;

      div {
        width: 65%;
        display: flex;

        @media (max-width: 767px) {
          width: 50%;
        }

        p {
          margin-left: 0.3rem;
          margin-top: 0.4rem;
          margin-bottom: 0;
          font-size: 13px;

          @media (max-width: 1460px) {
            font-size: 11px !important;
          }
        }

        .web-p {
          @media (max-width: 767px) {
            display: none;
          }
        }

        .mob-p {
          display: none;

          @media (max-width: 767px) {
            display: initial;
          }
        }

      }

      .btn {
        background-color: rgba($color: #5FF7FF, $alpha: 1);
        height: 1.8rem;
        font-size: 0.7rem;
        line-height: 0.8rem;
        vertical-align: middle;

        @media (max-width: 767px) {
          font-size: 9px;
          padding-top: 6px;
        }

        @media (max-width: 1340px) {
          font-size: 11px !important;
          padding-top: 6px;
        }
      }

      .get-btn {
        border-radius: 18px;
        background-color: rgba($color: #5FF7FF, $alpha: 0.1);
        color: rgba($color: #D6D6D6, $alpha: 0.9);

        &:hover {
          background-color: rgba($color: #5FF7FF, $alpha: 1);
          color: #000000;
        }

        @media (max-width: 1460px) {
          font-size: 11px !important;
        }

        @media (max-width: 1300px) {
          font-size: 9px !important;
        }

      }

      // p {
      //   margin-top: 0.4rem;
      //   margin-bottom: 0;
      //   @media (max-width: 770px) {
      //     font-size: 12px;
      //   }
      // }
    }

    .hide {
      visibility: collapse;
    }

    .show {
      position: relative;
      top: -29px;
    }

    .mid-scroll {
      @media (min-width: 1700px) {
        height: 300px !important;
      }
    }

    .sub-bottom {
      display: flex;
      width: 97%;
      margin: 0 auto;
      padding: 6px;
      justify-content: space-between;
      border: 1px solid rgba($color: #D6D6D6, $alpha: 0.9);
      border-radius: 10px;
      margin-top: 0.6rem;

      p {
        margin-bottom: 0;

        @media (max-width: 770px) {
          font-size: 12px;
        }

        @media (max-width: 1460px) {
          font-size: 11px;
        }
      }

      // .btn {
      //   background-color: rgba($color: #5FF7FF, $alpha: 1);
      //   height: 1.8rem;
      //   padding-top: 3px;
      //   font-size: 13px;
      //   @media (max-width: 767px) {
      //     font-size: 9px;
      //     padding-top: 6px;
      //   }
      // }

      // .get-btn {
      //   border-radius: 28px;
      //   font-size: 12px;
      //   background-color: rgba($color: #5FF7FF, $alpha: 0.1);
      //   color: rgba($color: #D6D6D6, $alpha: 0.9);

      //   &:hover {
      //   background-color: rgba($color: #5FF7FF, $alpha: 1);
      //     color: #000000;
      //   }

      // }
    }
  }

  .col {
    .search {
      width: 4%;

      @media(max-width: 767px) {
        width: 6%;
        right: 26px;
      }

      @media(min-width: 1600px) {
        width: 3%;
      }
    }
  }
}