.landing {

  .web-video {
    width: 100%;
    margin-top: 8.4rem;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .mob-video {
    display: none;

    @media (max-width: 767px) {
      display: initial;
      display: initial;
      width: 100%;
      margin-top: 9.5rem;
    }
  }

  .top-div {
    text-align: center;
    color: #FFFFFF;
    position: absolute;
    top: 23rem;

    @media (max-width: 1700px) {
      top: 15rem;
    }

    @media (max-width: 1470px) {
      top: 10rem;
    }

    @media (max-width: 767px) {
      top: 20rem;
    }

    h1 {
      font-family: $poppins;
      font-weight: 600;

      @media (max-width: 1240px) {
        font-size: 1.2rem;
      }

      @media (max-width: 767px) {
        font-size: 1.4rem;
      }

      span {
        color: #5ff7ff;
      }
    }

    .heading {
      margin-bottom: 1rem;
      margin-top: 0.5rem;
    }

    p {
      width: 40%;
      margin: 0 auto;
      color: rgba($color: #FFFFFF, $alpha: 0.7);

      @media (max-width: 1240px) {
        width: 60%;
      }

      @media (max-width: 767px) {
        width: 90%;
        margin-bottom: 0.5rem;
        font-size: 0.8rem;
      }
    }

    .btn {
      margin-top: 2rem;
      margin-left: 0.5rem;
      margin-bottom: 2rem;

      @media (max-width: 767px) {
        margin-bottom: 0;
        ;
        font-size: 0.9rem;
      }
    }

    .address-div {
      @media (max-width: 767px) {
        margin-top: 1rem;
      }

      p {
        font-size: 0.7rem;
      }

      img {
        margin-left: 1rem;
        width: 15px;
        cursor: pointer;
      }

      a {
        color: #5FF7FF;
      }
    }

    .p {
      display: block;
      font-family: $poppins;
      font-weight: 300;
      font-style: italic;
      margin-top: 3rem;
      margin-bottom: 1rem;
    }

    .power {
      margin: 0 auto;
      width: 66%;
      margin-bottom: 5rem;

      @media (max-width: 767px) {
        width: 100%;

        img {
          width: 8.8rem;
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  .key {
    background: #000000;
    text-align: center;
    padding-top: 1rem;
    color: #FFFFFF;

    .row {
      width: 70%;
      margin: 0 auto;
      margin-top: 1rem;
    }

    .box {
      background: #11062B;
      padding: 1rem;
      margin-left: 0.8rem;

      span {
        font-size: 0.9rem;
      }

      h1 {
        font-size: 2rem;
      }

    }

    h4 {
      color: #5FF7FF;
    }

    img {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    p {
      width: 60%;
      margin: 0 auto;
    }

    @media (max-width: 767px) {
      .box {
        width: 16rem;
        margin-left: 0;
        margin-bottom: 1rem;
      }

      p {
        width: 95%;
      }
    }
  }

  .pools {
    background-color: #000000;

    h3 {
      text-align: center;
      color: #5ff7ff;
      margin-top: 3rem;
      margin-bottom: 3rem;
    }

    .loader {
      margin: 0 auto;
    }

    .card {
      max-width: 330px;

      @media (min-width: 2200px) {
        max-width: 350px;
      }
    }

    .sub-pool {
      width: 90%;
      margin: 0 auto;
      margin-bottom: 3rem;

      .hide {
        visibility: hidden;
      }
    }
  }

  .buy-token {
    background-color: #000000;

    .sub-token {
      width: 60%;
      margin: 0 auto;
      background-color: #11062B;
      text-align: center;
      color: #FFFFFF;
      margin-bottom: 3rem;
      border-radius: 10px;

      @media (max-width: 767px) {
        width: 90%;
        padding-bottom: 1rem;
      }

      .emoji {
        margin-top: 2rem;
        margin-bottom: 1rem;
      }

      span {
        color: #5ff7ff;
      }

      p {
        width: 70%;
        margin: 0 auto;
        margin-bottom: 2rem;
        margin-top: 1rem;

        @media (max-width: 767px) {
          width: 90%;
        }
      }

      .mobile-pic {
        display: none;

        @media (max-width: 767px) {
          display: block;
          margin: 0 auto;
          margin-bottom: 1rem;
        }
      }

      .trad-div {
        display: flex;
        justify-content: center;

        @media (max-width: 767px) {
          display: none;
        }

        img {
          display: block;
          margin-bottom: 2rem;
          margin-left: 0.5rem;
        }

        .btn {
          background-color: #5ff7ff;
          color: #11062B;
          margin-bottom: 2rem;
          font-family: $poppins;
          font-weight: 600;
          border-radius: 1.5rem;
        }
      }
    }
  }

  .notified-div {
    background-color: #000000;

    .sub-notify {
      width: 60%;
      margin: 0 auto;
      background-color: #352476;
      text-align: center;
      color: #FFFFFF;
      border-radius: 0.8rem;
      padding: 1px;
      margin-bottom: 6rem;

      @media (max-width: 767px) {
        width: 90%;
      }

      h1 {
        font-size: 2.3rem;
        margin-top: 3rem;
        margin-bottom: 1rem;
        font-family: $poppins;
        font-weight: 600;

        @media (max-width: 767px) {
          font-size: 2rem;
        }
      }

      span {
        color: #5ff7ff;
      }

      p {
        color: rgba($color: #FFFFFF, $alpha: 0.7);
        font-family: $poppins;
        font-weight: 400;
        font-size: 1.1rem;
        width: 55%;
        margin: 0 auto;
        margin-bottom: 1rem;

        @media(max-width: 767px) {
          width: 93%;
        }
      }

      h4 {
        font-family: $poppins;
        font-weight: 600;
        font-size: 1.5rem;
        margin-bottom: 2rem;
      }

      .input-group {
        width: 50%;
        margin: 0 auto;
        margin-bottom: 4rem !important;

        @media (max-width: 767px) {
          width: 90%;
        }

        .form-control {
          background-color: #352476;
          border-bottom-left-radius: 9px;
          border-top-left-radius: 9px;
          height: calc(2.25rem + 8px) !important;
          color: #FFFFFF;
          border: 2.5px solid rgba($color: #5FF7FF, $alpha: 0.6);

        }

        ::placeholder {
          color: rgba($color: #FFFFFF, $alpha: 0.7);
          margin-left: 1rem;
        }

        .btn {
          background-color: #5ff7ff;
          color: #11062B;
          font-family: $poppins;
          font-weight: 600;
          border-top-right-radius: 9px;
          border-bottom-right-radius: 9px;
        }
      }
    }
  }
}