.contact {
  background: url('../../images/privacy/background.png');
  font-family: $poppins;

  .main {
    h3 {
      font-weight: 600;
      color: #5FF7FF;
      text-align: center;
      margin-top: 2rem;
    }

    p {
      color: #ffffff;
      text-align: center;
      font-weight: 400;
      font-size: 15px;
      width: 50%;
      margin: 0 auto;
      margin-bottom: 2rem;
      @media (max-width: 767px) {
       width: 90%;
      }
    }

    form {
      text-align: center;
      width: 40%;
      margin: 0 auto;
      background: linear-gradient(#4A289C, #00092B);
      padding-top: 3rem;
      margin-bottom: 5rem;
      border-radius: 15px;
      @media (max-width: 767px) {
       width: 90%;
      }
    
      .css-2b097c-container {
        width: 50%;
        margin: 0 auto;
        .css-2613qy-menu {
          background-color: #142C2C;
          }
        @media (max-width: 767px) {
          width: 80%;
        }
        .css-yk16xz-control {
          background-color: rgba($color: #FFFFFF, $alpha: 0.18) !important;
          border-radius: 5px;
          border: none;
          margin-bottom: 1rem;
        }
        .css-1okebmr-indicatorSeparator {
          display: none;
        }
        .css-1wa3eu0-placeholder {
          color: #ffffff;
          font-size: 12px;
        }
        .css-b8ldur-Input {
          color: #ffffff;
        }
        .css-1uccc91-singleValue {
          color: #ffffff;
        }
        .css-1pahdxg-control {
          border: none;
          border-radius: 5px;
          background-color: rgba($color: #FFFFFF, $alpha: 0.18) !important;
          margin-bottom: 1rem;
          box-shadow: none;
        }
      

      }
      ::placeholder {
        color: #ffffff;
        font-size: 12px;
        margin-left: 1rem;
      }

      .form-control {
        width: 50%;
        margin: 0 auto;
        background-color: rgba($color: #FFFFFF, $alpha: 0.18) !important;
        border: none;
        color: #ffffff;
        border-radius: 5px;
        @media (max-width: 767px) {
         width: 80%;
        }
      }
      label {
        color: #FFFFFF;
      }
      .btn {
        background-color: #5FF7FF !important;
        border: 1PX solid #5FF7FF;
        border-radius: 5px;
        color: #142C2C;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 20%;
        @media (max-width: 767px) {
          width: 30%;
        }
      }
    }
  }
}