.light {
    background: #F9F1EE 0% 0% no-repeat padding-box;
      opacity: 1;
      height: 6rem;
   .navbar-toggler {
        background-color: #FFFFFF !important;
      }
      .navbar-collapse {
        @media (max-width: 767px) {
          background: #0A1A1A 0% 0% no-repeat padding-box;
        }
      }
      .navbar-brand {
          img {
              margin-bottom: -10px;
              width: 62%;
              margin-left: 3rem;  
              @media (max-width: 767px) {
               margin-left: 0;
              }   
          }
      }
    
     
    
      .left {
        margin-top: -11px;
        .active {
            color: #F25921 !important;
        }
        .line {
          @media (max-width: 767px) {
            display: none;
           }
        }
        .nav-item {
            color: #000000;
            margin-left: 2.3rem;
          margin-top: 1.3rem;
    
          a {
            color: #000000;
            font-family: $poppins;
            font-weight: 500;
          }
          
        }
      }
    
      .right {  
        margin-top: 1rem;
        .img-item {
          margin-right: 1rem !important;
          @media (max-width: 767px) {
            display: none;
           }
         
        }    
        .nav-item {
          margin-right: 2rem;
         
          .btn {
            border: 1px solid #000000;
            border-radius: 28px;
            background-color: #F9F1EE;
            color: #000000;
            padding: 0.5rem 1.3rem 0.5rem 1.3rem;
            font-family: $poppins;
            font-weight: 500;
            @media (max-width: 767px) {
              margin-bottom: 1rem;
              margin-left: 1rem;
             }
            &:hover {
              background-color: #F25921;
              border: 1px solid #F25921;
              color: #F9F1EE;
            }
          }
          .active {
            background-color: #F25921 !important;
            border: 1px solid #F25921;
            color: #F9F1EE;
          }
    
        }
      }
    }