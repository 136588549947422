.nft-market {

  .web-video {
    margin-bottom: -0.5rem;
    width: 100%;
    @media (max-width: 767px) {
      display: none;
    }
  }
  .mob-video {
    display: none;
    margin-bottom: -0.5rem;
    width: 100%;
    @media (max-width: 767px) {
      display: initial;
    }
  }

  .mob-div {
    display: none;
    @media (max-width: 767px) {
      display: initial;
      img {
        width: 73%;
        margin-top: 2.5rem !important;
      }
    }
  }
  .web-div {
    @media (max-width: 767px) {
      display: none;
    }
  }
  .top-div {
    color: #FFFFFF;
    text-align: center;
    position: absolute;
    top: 1%;

    .logo-img {
      margin-top: 3rem;
      width: 44%;
    }

    h1 {
      font-family: $poppins;
      text-align: center;
      font-weight: 600;

      @media (max-width: 767px) {
        font-size: 1rem;
      }

      .lef-img {
        margin-right: 1rem;
        width: 5%;
      }

      .right-img {
        width: 5%;
        margin-left: 1rem;
      }
    }

    p {
      width: 85%;
      text-align: center;
      font-weight: 500;
      margin: 0 auto;
      margin-bottom: 2rem;
      font-size: 0.8em;
    }

    h4 {
      margin-bottom: 2rem;
    }

    .img-div {
      margin-bottom: 2rem;

      img {
        width: 7%;
        margin-left: 0.8rem;
      }
    }
    @media (max-width: 767px) {
      .img-div, h4, p {
        margin-bottom: 0.5rem;
      }
      .logo-img {
        margin-top: 1.5rem;
      }
    }

    .count {
      span {
        color: #5FF7FF;
        font-size: 3rem;
        letter-spacing: 8px;
      }
    }

    .nft-img {
      margin-top: 8rem;
    }
    .time {
      display: flex;
      justify-content: space-around;
      width: 44%;
      margin: 0 auto;

      @media (max-width: 1500px) {
        width: 64%;
      }

      @media (max-width: 767px) {
        width: 79%;
        justify-content: space-between;
      }

      span {
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        text-align: center;
        display: block;
        font-family: $poppins;
        font-weight: 300;
      }

      .line {
        display: block;
        width: 2px;
        height: 1.5rem;
        background-color: #FFFFFF;
        margin-top: 2rem;
      }
    }

    .btn {
      margin: 2rem 0 2rem 0.5rem;
    }
  }

  .last-div {
    background-color: #000000;
    padding-bottom: 5rem;

    .hrading {
      color: #5FF7FF;
      text-align: center;
      width: 100%;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    .card {
      .card-title {
        font-size: 1.1rem;

        @media(max-width: 1550px) {
          font-size: 0.8rem;
        }
      }

      .number {
        position: absolute;
        right: 4%;
        top: 3%;
        border-radius: 0.3rem;
        background-color: #5FF7FF;
        display: block;
        padding: 5px 10px 5px 10px;
      }

      .card-text {
        font-size: 0.9rem;
      }

      .card-body {
        padding: 0 !important;
      }

      .body {
        padding: 1.25rem;
        background-color: rgba($color: #4A289C, $alpha: 0.8);
        color: #FFFFFF;
      }

      .profile-div {
        display: flex;
        justify-content: space-between;

        p {
          margin-bottom: 0;
        }

        span {
          font-size: 0.7rem;
        }

        .price {
          font-size: 1rem;
          color: #5FF7FF;
          font-family: $poppins;
          font-weight: 600;
        }

        .sub-div {
          display: flex;
          align-items: center;

          div {
            margin-left: 0.3rem;

          }
        }
      }

      .network-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;

        div {
          display: flex;
          align-items: center;

          img {
            width: 2.2rem;
            margin-right: 7px;
          }
        }

        p {
          margin-bottom: 0;
          font-size: 0.7rem;
        }
      }
    }

   
    .aoTUt {
      right: -68px;
      z-index: 1;
      @media (max-width: 767px) {
        right: -21px;
      }
    }

    .dHQcjo {
      left: -68px;
      @media (max-width: 767px) {
        left: -21px;
      }
    }

    .btn {
      background: linear-gradient(#4A289C, #00092B);
      color: #FFFFFF;
      border: none;
    }
    .main-artist {
      text-align: center;
      background-repeat: no-repeat;
      background-position: center;
      color: rgba($color: #FFFFFF, $alpha: 0.8);
      .artist {
      background-color: rgba($color: #4A289C, $alpha: 0.8);
      .card-body {
        padding: 1.25rem !important;
       
      }
      }
     
      .card-img-top {
        width: 50%;
        margin: 0 auto;
        margin-top: 1rem;
      }

      .btn {
        background: #5FF7FF !important;
        color: #070707;
        margin-bottom: 1rem;
      }
    }
  }
}