.mystery {
  color: #ffffff;
  background: black;

  .first-div {
    background: url('../../images/mystery/background.svg');
    text-align: center;
    background-repeat: no-repeat;

    @media(min-width: 1840px) {
      background-size: 100%;
      background-position: center;

    }

    h1 {
      color: #5ff7ff;
      margin-bottom: 2rem;
      font-family: $poppins;
      font-weight: 600;
      font-size: 3rem;
    }

    img {
      width: 75%;
      margin: 0 auto;
      display: block;
      margin-top: 8rem;
      margin-bottom: 7rem;
    }

    .first-btn {
      background: #5ff7ff;
      color: #070707;
      margin-right: 0.5rem;
      border: 1px solid #5ff7ff;
    }

    p {
      margin-bottom: 2rem;
    }

    .btn {
      border-radius: 0.5rem;
    }

    h5 {
      margin-top: 2rem;
      font-family: $poppins;
      font-weight: 600;
    }
  }

  h2 {
    text-align: center;
    color: #5ff7ff;
    margin-top: 2rem;
  }

  .img-box {
    display: flex;
    justify-content: space-evenly;
    width: 79pc;
    margin: 0 auto;
    margin-bottom: 3rem;
    margin-top: 3rem;

    .card {
      width: 18pc;
      cursor: pointer;
    }

    .card-title {
      font-size: 1.2rem;
      margin: 0;
    }

    .card-text {
      font-size: 0.8rem;
    }

    .price-div {
      display: flex;
      justify-content: space-between;

      h5 {
        color: #5ff7ff;
      }

      p {
        text-align: end;
        margin-bottom: 0;
      }
    }

    @media (max-width: 1268px) {
      width: 62pc;

      .card {
        width: 15pc;
      }
    }

    @media (max-width: 767px) {
      display: block;
      width: auto;

      div {
        width: 90% !important;
        margin: 0 auto;
        margin-bottom: 0.5rem;

        img {
          margin-bottom: 0.5rem;
        }
      }

      .price-div {
        width: auto !important;

        h5 {
          text-align: end;
        }
      }
    }

  }
}

.buy-myster {
  background-color: #000000;
  color: #ffffff;

  .first-div {
    margin-top: 3rem;
  }

  .heading {
    margin-bottom: 2rem;

    span {
      color: #5ff7ff;
    }

    @media (max-width: 767px) {
      font-size: 1rem;
      text-align: center;
    }
  }

  .image-left {
    width: 90%;
    display: block;
    min-height: 87%;

    @media (min-width: 1520px) {
      width: 77%;
      height: 87%;
      margin: 0 auto;
    }

    @media (max-width: 767px) {
      margin: 0 auto;
    }
  }

  .box {
    background: linear-gradient(to bottom, #4A289C, #4A289C);
    mix-blend-mode: screen;
    border-radius: 5px;
    padding: 2rem;
    margin-top: 3.5rem;
    margin-bottom: 2rem;
    padding-bottom: 3rem;
    width: 90%;
    min-height: 87%;

    @media (min-width: 1520px) {
      width: 77%;
    }

    @media (max-width: 767px) {
      margin-bottom: 0.5rem;
      text-align: center;
      margin: 0 auto;
      margin-top: 2.5rem;
      padding: 1rem;
    }

    .top-div {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      h2 {
        font-size: 1.2rem;

        img {
          width: 1.2rem;
        }

        @media (max-width: 1200px) {
          font-size: 0.9rem;
        }
      }

      div {
        img {
          margin-left: 0.5rem;

          @media (max-width: 1200px) {
            width: 1rem;
          }
        }
      }
    }

    h6 {
      background: rgba($color: #5ff7ff, $alpha: 0.25);
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 5px;
      color: #5ff7ff;
      width: auto;
      padding: 0.5rem;
      text-align: center;
      max-width: 40%;
    }

    p {
      color: rgba($color: #ffffff, $alpha: 0.8);
      font-size: 0.8rem;
    }

    .mid-div {
      display: flex;
      margin-top: 1rem;
      justify-content: space-between;
      margin-bottom: 1rem;

      span {
        font-size: 0.8rem;
      }

      h5 {
        color: #5ff7ff;
        margin-top: 0.5rem;

        span {
          color: #ffffff;
        }
      }
    }

    .address {
      color: #5ff7ff;
      margin-bottom: 0.5rem;
      display: block;
      font-size: 0.8rem;

      img {
        width: 1rem;
      }
    }

    .progress {
      background-color: rgba($color: #5ff7ff, $alpha: 0.1);
      margin-top: 1rem;
      border-radius: 0.5rem;
      margin-bottom: 0.5rem;

      .progress-bar {
        border-radius: 0.5rem;
        background-color: #5ff7ff;
      }
    }

    .progress-div {
      display: flex;
      justify-content: space-between;
    }

    .btn {
      display: block;
      margin: 0 auto;
      background: #5ff7ff;
      color: #070707;
      border: 0;
      width: auto;
    }
  }

  .detail-box {
    background: linear-gradient(#4A289C, #00092B);
    padding: 1rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 95%;

    @media (min-width: 1520px) {
      width: 77%;
      margin: 0 auto;
    }

    ul {
      li {
        margin-left: 1rem;
        padding-bottom: 0.8rem;

        a {
          color: #ffffff;

          &:hover {
            text-decoration: none;
          }
        }
      }

      .active {
        border-bottom: 2px solid #5ff7ff;

        a {
          color: #5ff7ff;
        }
      }
    }

    hr {
      border-top: 1px solid rgba($color: #5ff7ff, $alpha: 0.3);
      margin-top: 0rem;
    }

    .rules {
      p {
        width: 70%;
        font-size: 0.9rem;
        font-family: $poppins;
        font-weight: 400;

        @media (max-width: 767px) {
          width: 95%;
        }
      }
    }

    .table {
      border-collapse: separate;
      border-spacing: 0 10px;

      td{
        vertical-align: middle;
      }
      
      .top-div {
        border-radius: 6px;
        color: rgba(255, 255, 255, 0.8);
        background: rgba(255, 255, 255, 0.1);
      }

      .top-div th:first-child {
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
      }

      .top-div th:last-child {
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
        text-align: center;

        span {
          visibility: hidden;
        }
      }

      .sec-div {
        background: rgba($color: #5ff7ff, $alpha: 0.1);

        img {
          width: 1.5rem;
        }

        .change {
          color: #5ff7ff;
        }
      }
      .btn {
        border-radius: 5px;
        font-size: 12px;
        font-family: "Poppins";
        font-weight: 600;
      }
      .sec-div td:first-child {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }

      .sec-div td:last-child {
        border-right: 1px solid #4f3892;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;

        span {
          visibility: hidden;
        }
      }
    }
  }
  .scroll {
    margin-top: -2.3rem;
  }
}
html{
  scroll-behavior: smooth;
}
.hide {
  visibility: collapse;
}
